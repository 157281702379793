import React, { useEffect, useState, useRef } from 'react';

import PropTypes from 'prop-types';

import WalletSection from '../components/Sections/WalletSection/Wallet';

import ShuflLiquidity from 'Root/images/liquidity.jpg';

import useNfdHook from 'Hooks/useNFDHook';

import { connect } from 'react-redux';

import { getNfdInfo, setDefaultNfd } from '../services/utils';

import { useWallet } from '@txnlab/use-wallet';
import useIsSticky from 'Hooks/useIsSticky';

const Wallet = ({ address }) => {
  const { tabHeight } = useIsSticky()
  return (
    <div className="rounded-t-2xl relative">
      <CollectionCard />
      <div className="flex justify-center py-4 mx-0 mx-md-4">
        <WalletSection />
      </div>
    </div>
  );
};

const CollectionCard = () => {
  const { activeAddress } = useWallet()
  const [nfd, setNfd] = useState(setDefaultNfd(activeAddress));
  const [refresh, setRefresh] = useState(false);
  const { isSticky, cardRef, navHeight } = useIsSticky()
  const { data, isFetchting: loading, error } = useNfdHook();

  useEffect(() => {
    if (error) return;
    if (loading) return;
    if (data) {
      setNfd(getNfdInfo(data));
    }
  }, [data, loading, error, activeAddress]);

  return (
    <div id='Account_Banner' className={`w-full max-w-screen-lg transition-all duration-300 shadow-xl m-auto min-h-[4rem] flex items-start justify-center dark:backdrop-blur-2xl z-50 shadow-md border-b-1 border-dark-border`}>
      <div className={`w-full h-full flex items-start bg-mutant-nav dark:bg-dark-nav`}>
        <div className="relative w-full">
          <AvatarIcon nfd={nfd} isSticky={isSticky} />
        </div>
      </div>
    </div>
  );
};

function Avatar({ isSticky, nfd }) {
  return <div className={`absolute z-10 transition-all duration-300
    ${isSticky ?
      'top-2 bottom-2 left-4' :
      '-bottom-12 left-1/2 -translate-x-1/2'
    }
  `}>
    <div className={`rounded-full overflow-hidden transition-all duration-300 ${isSticky ? 'border-none w-12 h-12' : 'w-24 h-24 border-4 border-white/5'
      }
    `}>
      <img
        id='avatar'
        src={nfd && nfd.avatar}
        alt="avatar"
        className="w-full h-full object-cover"
      />
    </div>
  </div>
}
function AvatarIcon({ isSticky, nfd }) {
  return <div className={`z-10 transition-all duration-300`}>
    <div className={`rounded-full overflow-hidden transition-all duration-300 border-none w-12 h-12`}>
      <img
        id='avatar'
        src={nfd && nfd.avatar}
        alt="avatar"
        className="w-full h-full object-cover"
      />
    </div>
  </div>
}
function Banner({ isSticky }) {
  return <div className={`transition-all duration-300 rounded-t-2xl
    ${isSticky ? 'opacity-0 h-0 overflow-hidden' : 'opacity-100'}`}>
    <img
      id='liquidity'
      src={ShuflLiquidity}
      className="w-full h-auto rounded-t-2xl"
      alt="Account Board"
    />
  </div>
}
const mapStateToProps = (state) => ({
  address: state.connect?.address,
});

const mapDispatchToProps = (dispatch) => ({
  showLoadingModal: () => dispatch(openLoadingModal()),
  closeModal: (id) => dispatch(closeModal(id)),
});

Wallet.propTypes = {
  address: PropTypes.string,
  myAlgoWallet: PropTypes.object,
  closeModal: PropTypes.func,
  showLoadingModal: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(Wallet);
