import { getNFT } from 'Queries/queries'
import { useQuery } from "@tanstack/react-query"
import { client } from "Apis/graphql"
import { eligibleAssets } from "../helpers/utils/nftIndexes"
import { useLocalWallet } from "../context/walletProvider"
import { queryKeys } from "../services/api/queries"
import { useWallet } from '@txnlab/use-wallet'




function getIntersection(allAssets) {
  const assetIds = allAssets?.map(asset => asset['asset-id']) || []
  return assetIds?.filter(item => eligibleAssets.includes(item.toString())) || [];
}
async function getNfts(intersection) {
  {
    let eligibleNFTs = []
    let totalProcessed = 0

    const responses = await Promise.all(
      intersection.map(async (x, y) => {
        return await client.graphql({
          query: getNFT,
          variables: {
            id: x.toString(),
          }
        })
      })
    )
    responses.map(response => {
      if (response?.data?.getNFT) {
        eligibleNFTs.push(response.data.getNFT)
      }

    })
    eligibleNFTs = eligibleNFTs.map(nft => ({
      image: nft.image,
      collection: nft.collection,
      collectionID: nft.collectionID,
      creator: nft.creator,
      name: nft.name,
      rank: nft.rank,
      rarityScore: nft.rarityScore,
      tradeable: nft.tradeable,
      traits: nft.traits,
      unitName: nft.unitName,
      _version: nft._version,
      ...nft.collectionInfo,
      id: nft.id,
    }))

    const groupedByCollection = eligibleNFTs.reduce((acc, nft) => {
      if (!acc[nft.collection]) {
        acc[nft.collection] = []
      }
      acc[nft.collection].push(nft)
      return acc
    }, {})

    return {
      eligibleNFTs,
      groupedByCollection,
      totalEligible: eligibleNFTs.length,
      totalProcessed
    }
  }
}
// New hook for eligible NFTs
export default function useEligibleNFTs(activeAddress, allAssets) {
  return useQuery({
    queryKey: queryKeys.eligibleNfts.byAssetIds(activeAddress, allAssets),
    queryFn: async ({ signal }) => {
      const intersection = getIntersection(allAssets)
      return await getNfts(intersection)
    },
    enabled: Boolean(
      allAssets?.length > 0
    ),
    gcTime: 0,
    enabled: Boolean(activeAddress && allAssets && allAssets.length > 0),
    staleTime: 0,      // 1 minute
    refetchInterval: 120000, // 2 minutes - ~720 requests per day per user
    retry: 3,
    retryDelay: (attemptIndex) => Math.min(1000 * Math.pow(2, attemptIndex), 30000),
    // refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
  })
}