import { encodeAddress, base64Decode, stringFromCharCode, stringFromChar, base64EncodeUint8, encodeAlgoAddress } from './coders'
import { getGlobalState, getApprovalProgram, getClearStateProgram, getPublicKey } from './formatters'
const { findAppState } = require('./filters');
function castListingData(results, userListings) {
  try {
    let listingData = [];
    results.forEach((result, index) => {
      // Check if listed (not unlisted/sold)
      const state = findAppState(
        result.params['global-state'],
        'global_list_status'
      );
      if (state && state.value.uint === 1) {
        const price = findAppState(
          result.params['global-state'],
          'global_list_price'
        );
        const owner = findAppState(
          result.params['global-state'],
          'global_list_owner'
        );
        const status = findAppState(
          result.params['global-state'],
          'global_list_status'
        );
        listingData.push({
          ...result.id,
          creator: result.params.creator,
          price: parseInt(price ? price.value.uint : '0'),
          owner: owner
            ? encodeAddress(base64Decode(owner.value.bytes))
            : 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA',
          status: status ? status.value.uint : -1,
          royalty: '5%',
          ...userListings[index],
        });
      }
    });

    return listingData;
  } catch (error) {
    console.error("listingData: ", error.message)
    return []
  }
}

function convertKeyToString(obj) {
  if (obj instanceof Uint8Array) {
    return stringFromChar(obj)
  } else {
    return stringFromCharCode(obj)
  }
}
function convertProgramToBase64(obj) {
  if (obj instanceof Uint8Array) {
    return base64EncodeUint8(obj)
  } else {
    return obj
  }
}

function getGlobalStateValue(value) {
  switch (value.type) {
    case 1:

      return convertKeyToString(value.bytes)
    case 2:

      return Number(value.uint)

    default:
      return undefined
  }
}

function convertAddress(addr) {
  try {
    if (addr instanceof Uint8Array || addr instanceof Uint32Array) {
      return encodeAddress(addr)
    } else {
      const isB64 = isBase64(addr)
      return isB64 ? encodeAlgoAddress(addr) : addr
    }
  } catch (error) {
    return base64EncodeUint8(addr)
  }
}
function isBase64(str) {
  try {
    // Check if string is properly padded
    if (str.length % 4 !== 0) {
      return false;
    }

    // Check if string matches the Base64 pattern
    const base64Pattern = /^[A-Za-z0-9+/]+={0,2}$/;
    return base64Pattern.test(str);
  } catch (err) {
    return false;
  }
}
function decodeAppParams(app) {
  let params = app['params']
  const id = Number(app.id)
  const creator = convertAddress(getPublicKey(params.creator))
  let globalState = getGlobalState(params)
  globalState = globalState && globalState.map(state => {
    const key = convertKeyToString(state.key)
    const value = key === 'global_lend_address' ?
      convertAddress(state.value.bytes) : getGlobalStateValue(state.value)
    return {
      key,
      value
    }
  })
  const approvalProgram = convertProgramToBase64(getApprovalProgram(params))
  const clearStateProgram = convertProgramToBase64(getClearStateProgram(params))
  params = {
    creator,
    globalState,
    approvalProgram,
    clearStateProgram
  }
  return {
    id,
    params
  }
}

function decodeAppParamsG(app) {
  let params = app['params']
  const id = Number(app.id)
  const creator = convertAddress(getPublicKey(params.creator))
  let globalState = getGlobalState(params)
  const gState = {}
  if (!globalState) return undefined
  globalState = globalState.map(state => {
    const key = convertKeyToString(state.key)
    const value = key === 'global_lend_address' ?
      convertAddress(state.value.bytes) : getGlobalStateValue(state.value)
    gState[key] = value
    return {
      key,
      value
    }
  })
  const approvalProgram = convertProgramToBase64(getApprovalProgram(params))
  const clearStateProgram = convertProgramToBase64(getClearStateProgram(params))
  params = {
    creator,
    ...gState
  }
  return {
    id,
    ...params
  }
}

export {
  castListingData,
  convertKeyToString,
  getGlobalStateValue,
  decodeAppParams,
  convertProgramToBase64,
  decodeAppParamsG
};
