import React, { useEffect, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { showPaymentAmount } from 'Apis/transactions';

const LoanPercentageSelector = ({ loanAmount, setTotalRepayable, percentage, setPercentage }) => {

  const percentageOptions = [25, 50, 75, 100];

  const handleDecrease = () => {
    const currentIndex = percentageOptions.indexOf(percentage);
    if (currentIndex > 0) {
      setPercentage(percentageOptions[currentIndex - 1]);
    }
  };

  const handleIncrease = () => {
    const currentIndex = percentageOptions.indexOf(percentage);
    if (currentIndex < percentageOptions.length - 1) {
      setPercentage(percentageOptions[currentIndex + 1]);
    }
  };

  const calculatePayment = (percent) => {
    return (loanAmount * percent) / 100;
  };

  useEffect(() => {
    setTotalRepayable(showPaymentAmount(loanAmount, percentage))
  }, [loanAmount, percentage])
  return (
    <div className="w-full max-w-md">
      <div className="hidden text-lg font-semibold">
        Loan Amount: ${loanAmount.toLocaleString()}
      </div>

      <div className="flex items-center justify-center">
        <button
          onClick={handleDecrease}
          disabled={percentage === percentageOptions[0]}
          className="p-2 rounded-full hover:bg-white/20 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          <ChevronLeft className="w-6 h-6 text-white/50" />
        </button>

        <div className="text-sm font-bold text-dark-text py-4 px-5">
          {percentage}%
        </div>

        <button
          onClick={handleIncrease}
          disabled={percentage === percentageOptions[percentageOptions.length - 1]}
          className=" p-2 rounded-full hover:bg-white/20 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          <ChevronRight className="w-6 h-6 text-white/50" />
        </button>
      </div>

      <div className="hidden space-y-4 p-4 bg-gray-50 rounded-lg">
        <div className="flex justify-between">
          <span className="text-gray-600">Selected Percentage:</span>
          <span className="font-semibold">{percentage}%</span>
        </div>
        <div className="flex justify-between">
          <span className="text-white">Payment Amount:</span>
          <span className="font-semibold">
            ${showPaymentAmount(loanAmount, percentage)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default LoanPercentageSelector;