import React from 'react';

import { Provider } from 'react-redux';

import ReactDOM from 'react-dom/client';

import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';

import { Toaster } from 'react-hot-toast';

import store from './services/reducers/store';

import ScrollToTop from './helpers/scrollToTop';

import './index.scss';

import { Amplify } from 'aws-amplify';

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { config } from "Context/credentialsProvider"

Amplify.configure(config)

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Toaster
      position="bottom-right"
      toastOptions={{
        duration: 3000,
        style: {
          border: `1px solid #000`,
          padding: '16px',
          background: 'rgba(0,0,0,0.5)',
          color: '#fff',
        },
        iconTheme: {
          primary: `#000`,
          secondary: '#fff',
        },
      }}
    />
    <Router>
      <QueryClientProvider client={queryClient}>
        <ScrollToTop />
        <App />
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
    </Router>
  </Provider>,
);
