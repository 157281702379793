import AlgoImage from 'Root/images/algo_white.png';
import { NavButton } from '../Buttons/navButton';

export default function DetailComponent({ loanamount, isHistory, buttonProps }) {
  const { text, disabled, onClick } = buttonProps

  return <div className="p-3 border-t border-dark-border bg-dark-table">
    <div className="flex items-center justify-between">
      <div className="flex-col text-start text-truncate">
        <span className='flex items-center gap-1 text-white'>
          <img src={AlgoImage} className="w-2.5 inline" alt="$ALGO" />
          {loanamount}
        </span>
      </div>
      <div className="w-1/2 text-right">
        <NavButton
          isHistory={isHistory}
          text={text}
          disabled={disabled}
          onClick={onClick}
        />
      </div>
    </div>
  </div>
}