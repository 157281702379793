import { useState, useRef, useEffect } from 'react';

export default function useIsSticky() {
  const [isSticky, setIsSticky] = useState(true);
  const [navHeight, setNavHeight] = useState(0);
  const [assetTabPadding, setAssetTabPadding] = useState(0)
  const cardRef = useRef(null);
  const observerRef = useRef(null);
  const navRef = useRef(null);
  const [assetTabHeight, setAssetTabHeight] = useState(undefined)
  const [tabHeight, setTabHeight] = useState(undefined)
  // Track nav height
  useEffect(() => {
    const updateNavHeight = () => {
      // Get the main nav element
      const mainNav = document.querySelector('nav'); // Or use a specific selector
      if (mainNav) {
        const height = mainNav.offsetHeight;
        setNavHeight(height);
      }
    };

    // Initial measurement after a short delay to ensure rendering
    requestAnimationFrame(updateNavHeight);

    // Track changes in nav height
    const resizeObserver = new ResizeObserver(() => {
      requestAnimationFrame(updateNavHeight);
    });

    const mainNav = document.querySelector('nav');
    if (mainNav) {
      resizeObserver.observe(mainNav);
    }

    // Also listen for window resize
    window.addEventListener('resize', updateNavHeight);

    return () => {
      resizeObserver.disconnect();
      window.removeEventListener('resize', updateNavHeight);
    };
  }, []);

  // Sticky observer logic
  useEffect(() => {
    observerRef.current = document.createElement('div');
    observerRef.current.style.position = 'absolute';
    observerRef.current.style.top = '0';
    observerRef.current.style.height = '1px';
    observerRef.current.style.width = '100%';
    observerRef.current.style.pointerEvents = 'none';

    if (cardRef.current) {
      cardRef.current.prepend(observerRef.current);
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        // setIsSticky(!entry.isIntersecting);
      },
      {
        threshold: 0,
        rootMargin: "0px"
      }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
        observerRef.current.remove();
      }
    };
  }, []);

  useEffect(() => {
    const padding = navHeight * 2 + 25
    setAssetTabPadding(padding)
    const assetTab = document.getElementById('Asset_Tab')
    // console.log({ ohhsetHieght: assetTab.offsetHeight })
    // console.log(`calc(100vh - ${padding + assetTab.offsetHeight}px)`)
    if (assetTab) {
      setAssetTabHeight(`calc(100vh - ${padding + assetTab.offsetHeight - 10}px)`)
      setTabHeight(`calc(100vh - ${navHeight * 2}px)`)
    }
  }, [navHeight])

  return { isSticky, cardRef, observerRef, navHeight, navRef, assetTabPadding, assetTabHeight, tabHeight };
}