import { CONNECT_WALLET, DISCONNECT_WALLET } from '../../constants/ActionTypes';

import { PeraWalletConnect } from '@perawallet/connect';
import { DeflyWalletConnect } from '@blockshake/defly-connect';

const defaultState = null;

export const connect = (state = defaultState, action) => {
  switch (action.type) {
    case CONNECT_WALLET:
      return {
        ...state,
        address: action.address,
      };
    case DISCONNECT_WALLET:

      return {
        ...state,
        address: null,
      };
    default:
      return state;
  }
};
