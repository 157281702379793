import React from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { openLendModal, openPayModal } from 'Root/services/actions/actions';

import ImageComponent from './ImageComponent';

import TitleComponent from './TitleComponent';

import { useClientContext } from '../../context/clientProvider';

import DetailComponent from './DetailComponent';

const NFTCard = ({
  showLendModal,
  showPayModal,
  id,
  loanId,
  name,
  collection,
  image,
  rank,
  loanamount,
  price,
  isOwner,
  amount = 1,
  loanLength,
  endDate,
  className,
  loanState,
}) => {
  const { isMobile } = useClientContext()
  const isHistory = loanState === 'history'
  const isActive = loanState === 'active'
  const isEligible = loanState === 'eligible'
  const text = loanState === "eligible" ? "Loan" : loanState === "active" ? "Pay" : loanState === "overdue" ? "Repay" : "history"
  const modal = loanState === "eligible" ?
    { func: showLendModal, props: { id, image, name, collection, loanamount }, loanState } :
    loanState === "active" ?
      { func: showPayModal, props: { id, loanId, price, image, name, collection, isOverDue: false, endDate }, loanState }
      : loanState === "overdue" ?
        { func: showPayModal, props: { id, loanId, price, image, name, collection, isOverDue: true, endDate }, loanState } :
        { func: () => { }, props: {}, loanState }
  const disabled = loanState === 'history'

  const openModal = () => {
    if (modal.loanState === 'eligible') {
      const { id, image, name, collection, loanamount } = modal.props;
      modal.func(id, image, name, collection, loanamount);
    }
    else if (modal.loanState === 'active') {
      const { id, loanId, price, image, name, collection, isOverDue, endDate } = modal.props;
      modal.func(id, loanId, price, image, name, collection, isOverDue, endDate);
    }
    else if (modal.loanState === 'overdue') {
      const { id, loanId, price, image, name, collection, isOverDue, endDate } = modal.props;
      modal.func(id, loanId, price, image, name, collection, isOverDue, endDate);
    } else {
      console.error("FNYS")
    }
  }
  return (
    <div className={`relative w-full ${isMobile ? '' : 'hover:[transform:scale(1.04)] transition-transform duration-200'}`}>
      <div className="snow-cap"></div>
      <div
        className="block relative h-full rounded-nft border border-dark-border text-gray-900 ">
        {rank && (
          <span className="rarity">
            <small className="text-xs">
              <i className="fa-regular fa-crown"></i> {rank}
            </small>
          </span>
        )}

        <ImageComponent image={image} endDate={endDate} isHistory={isHistory} isEligible={isEligible} />

        <TitleComponent nftName={name} amount={amount} id={id} endDate={endDate} />
        <DetailComponent
          loanamount={isEligible ? loanamount : price}
          isHistory={isHistory}
          buttonProps={{
            text,
            disabled,
            onClick: openModal
          }}
        />
      </div>
    </div>
  );

};

const mapStateToProps = (state) => ({
  address: state.connect?.address,
});

const mapDispatchToProps = (dispatch) => ({
  showLendModal: (
    id,
    image,
    name,
    collection,
    loanamount,
  ) => dispatch(
    openLendModal(
      id,
      image,
      name,
      collection,
      loanamount,
    ),
  ),
  showPayModal: (id, loanId, loanPaidAmount, image, name, collection, isOverDue, endDate) =>
    dispatch(openPayModal(id, loanId, loanPaidAmount, image, name, collection, isOverDue, endDate)),
});

NFTCard.propTypes = {
  showLendModal: PropTypes.func,
  showPayModal: PropTypes.func,
  id: PropTypes.number,
  loanId: PropTypes.number,
  name: PropTypes.string,
  collection: PropTypes.string,
  image: PropTypes.string,
  rank: PropTypes.number,
  loanamount: PropTypes.number,
  price: PropTypes.number,
  isOwner: PropTypes.bool,
  small: PropTypes.bool,
  amount: PropTypes.number,
  loanLength: PropTypes.number,
  loanState: PropTypes.string,
  endDate: PropTypes.number,
};
export default connect(mapStateToProps, mapDispatchToProps)(NFTCard);
