export const listCustomNFTS = /* GraphQL */ `
  query ListNFTS(
    $filter: ModelNFTFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNFTS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        unitName
        image
        collection
        traits
        rarityScore
        rank
        creator
        tradeable
        collectionID
        collectionInfo {
          loanAmount
          loanAPR
          loanLength
          rewardAmount
          hasRewardToken
          tokenASA
          tokenDecimal
      }
        _version
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getNFTForApp = /* GraphQL */ `
  query GetNFT($id: ID!) {
    getNFT(id: $id) {
      id
      name
      image
      collection
      rank
      creator
      tradeable
      collectionID
      collectionInfo {
        id
        collection
        loanAmount
        loanAPR
        loanLength
        rewardAmount
        hasRewardToken
        tokenASA
        tokenDecimal
      }
      _version
    }
  }
`;