import { shortenAddress } from 'Root/helpers/shortenAddress';
function hasOwner(obj) {
  return 'owner' in obj
}
function hasNfdAccount(obj) {
  return 'nfdAccount' in obj
}
function hasTwitter(obj) {
  return 'twitter' in obj
}
function hasVerified(obj) {
  return 'verified' in obj
}

function hasWebsite(obj) {
  return 'url' in obj
}

function hasUrl(obj) {
  return 'url' in obj
}
function hasName(obj) {
  return 'name' in obj
}
function hasLinkedIn(obj) {
  return 'linkedin' in obj
}
function hasDiscord(obj) {
  return 'discord' in obj
}

function hasGithub(obj) {
  return 'github' in obj
}
function hasEmail(obj) {
  return 'email' in obj
}
function hasDomain(obj) {
  return 'domain' in obj
}
function hasBio(obj) {
  return 'bio' in obj
}
function hasAvatar(obj) {
  return 'avatar' in obj
}
function hasUserDefined(obj) {
  return 'userDefined' in obj
}
function hasProperties(obj) {
  return 'properties' in obj
}
export function getNfdInfo(nfd) {
  let name = undefined
  let nfdAccount = undefined
  let owner = undefined
  let properties = undefined
  let userDefined = undefined
  let avatar = undefined
  let bio = undefined
  let discord = undefined
  let domain = undefined
  let email = undefined
  let github = undefined
  let linkedin = undefined
  let userName = undefined
  let url = undefined
  let website = undefined
  let verified = undefined
  let twitter = undefined

  name = nfd['name']
  nfdAccount = nfd['nfdAccount']
  owner = nfd['owner']
  if (hasProperties(nfd)) {
    properties = nfd['properties']
    if (hasUserDefined(properties)) {
      userDefined = properties['userDefined']
      if (hasAvatar(userDefined)) {
        avatar = userDefined['avatar']
      }
      if (hasBio(userDefined)) {
        bio = userDefined['bio']
      }
      if (hasDiscord(userDefined)) {
        discord = userDefined['discord']
      }
      if (hasDomain(userDefined)) {
        domain = userDefined['domain']
      }
      if (hasEmail(userDefined)) {
        email = userDefined['email']
      }
      if (hasGithub(userDefined)) {
        github = userDefined['github']
      }
      if (hasLinkedIn(userDefined)) {
        linkedin = userDefined['linkedin']
      }
      if (hasName(userDefined)) {
        userName = userDefined['name']
      }
      if (hasUrl(userDefined)) {
        url = userDefined['url']
      }
      if (hasWebsite(userDefined)) {
        website = userDefined['webiste']
      }
    }
    if (hasVerified(properties)) {
      verified = properties['verified']
      if (hasTwitter(verified)) {
        twitter = verified['twitter']
      }
    }
  }

  return {
    name: name ? name : userName ? userName : owner,
    avatar: avatar ? avatar : 'https://app.nf.domains/img/nfd-image-placeholder_gold.jpg',
    bio,
    discord,
    address: nfdAccount ? shortenAddress(nfdAccount) : owner ? shortenAddress(owner) : undefined,
    email,
    linkedin,
    twitter: twitter ? `https://x.com/${twitter}` : undefined,
    github,
    link: url ? url : website ? website : domain
  }
}

export function setDefaultNfd(address) {
  return {
    name: address ? shortenAddress(address) : "Connect Wallet",
    avatar: 'https://app.nf.domains/img/nfd-image-placeholder_gold.jpg',
    bio: undefined,
    discord: undefined,
    address: shortenAddress(address),
    email: undefined,
    linkedin: undefined,
    twitter: undefined,
    github: undefined,
    link: undefined
  }
}