import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ deadlineUnix, isHistory, center = false }) => {
  if (isHistory) {
    return null
  }
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  const [isExpired, setIsExpired] = useState(false);
  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = Math.floor(Date.now() / 1000); // Current unix timestamp in seconds
      const difference = deadlineUnix - now;

      if (difference <= 0) {
        setIsExpired(true);
        return;
      }

      const days = Math.floor(difference / 86400);
      const hours = Math.floor((difference % 86400) / 3600);
      const minutes = Math.floor((difference % 3600) / 60);
      const seconds = difference % 60;

      setTimeLeft({ days, hours, minutes, seconds });
    };

    // Calculate immediately
    calculateTimeLeft();

    // Update every second
    const timer = setInterval(calculateTimeLeft, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(timer);
  }, [deadlineUnix]);

  const TimeUnit = ({ value, label }) => (
    <div className="flex flex-col items-center p-2 bg-dark-nav rounded-tab max-w-20">
      <span className="text-sm font-bold text-dark-text">
        {value.toString().padStart(2, '0')}
      </span>
      <span className="text-xs text-dark-text-secondary">{label}</span>
    </div>
  );

  if (isExpired) {
    return (
      <div className="w-full max-w-lg">
        <div className={`${center ? "" : "pt-6"}`}>
          <div className={`${center ? "justify-center" : "justify-end "} flex pr-2`}>
            <div className="flex flex-col items-center p-2 bg-mutant-bg rounded-tab max-w-20">
              <span className="text-sm font-bold text-dark-text">
                OverDue
              </span>
              <span className="text-xs text-dark-text-secondary">&nbsp;</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full max-w-lg">
      <div className={`${center ? "" : "pt-6"}`}>
        <div className={`${center ? "justify-center" : "justify-end "} flex pr-2`}>
          {timeLeft.days > 0 ?
            (<TimeUnit value={timeLeft.days} label="Days" />)
            :
            timeLeft.hours > 0 ?
              (<TimeUnit value={timeLeft.hours} label="Hours" />)
              :
              timeLeft.minutes > 0 ?
                (<TimeUnit value={timeLeft.minutes} label="Minutes" />)
                :
                (<TimeUnit value={timeLeft.seconds} label="Seconds" />)
          }
        </div>
      </div>
    </div>
  );
};

export default CountdownTimer;