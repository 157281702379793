import React from 'react';

import { Link } from 'react-router-dom';

import ATM from 'Root/images/atm-default.jpg';

import NavLink from '../../Buttons/navButton';

const NotFoundSection = () => {
  document.title = 'Goanna - Inside The Bank';

  return (
    <div className='p-4 rounded-big max-w-screen-lg m-auto'>
      <div className="atm border border-dark-border rounded-big bg-dark-table">
        <div className="machine">
          <Link to={`/account`} className="nfts">My NFTs</Link>
          <Link to={`/account#loans`} className="loans">My Loans</Link>
          <div className="imgs change rounded-big">
            <img src={ATM} className="rounded-big w-100" />
          </div>
        </div>
        <div className="bestview d-block d-lg-none p-5 text-center">
          <NavLink link={`/account`} text={'My NFTs'} /> &nbsp;
          <NavLink link={`/account#loans`} text={'My Loans'} />
        </div>
      </div>
    </div>
  );
};

export default NotFoundSection;
