import { useEffect } from "react"
import { useLoanQueries } from "../../context/loanQueryProvider"
import ComplexLoader from "../helpers/loading"

export default function StatCard({ title, stat, statChange, statChageStyle, emphasis }) {
  return <div className="w-full max-w-full px-3 sm:w-1/2 sm:flex-none xl:w-1/4 mb-3">
    <div className="relative flex flex-col min-w-0 break-words transition-all duration-200 ease-out border-0 border-transparent shadow-xl dark:shadow-dark-xl hover:transform3d-hover transform3d rounded-2xl bg-clip-border backdrop-blur bg-dark-card gradient-border-dark">
      <div className="flex-auto p-4 grow w-full">
        <div className="flex flex-row -mx-3">
          <div className="flex-none max-w-full px-3">
            <div>
              <p className="mb-0 font-sans text-xs leading-normal text-dark-brand dark:text-mutant-brand">{title}</p>
              <h5 className="mb-2 text-xl text-white">
                {stat}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
export function Stats({ monthlyLoans, totalLoans, loading }) {
  return <div className="grid grid-cols-2 lg:grid-cols-2 gap-0 lg:gap-4 mb-4 text-center items-center w-full">
    <Card className='rounded-tl-lg' loading={loading} title={'Monthly Loans'} stat={monthlyLoans} />
    <Card className='rounded-tr-lg' loading={loading} title={'Total Loans'} stat={totalLoans} />
    {/* <Card className='rounded-bl-lg' loading={loading} title={''} stat={''} />
    <Card className='rounded-br-lg' loading={loading} title={''} stat={''} /> */}
  </div>
}
export function Card({ className, title, stat, loading, textSize = 'text-xl', children }) {
  return <div className={`w-full ${className} border border-dark-stats-border bg-dark-table`}>
    <div className="py-3 px-1 md:p-4 rounded-lg first:rounded-lg">
      {children && children}
      {!children && title && <p className="mb-2 font-sans text-xs leading-normal text-dark-brand dark:text-mutant-brand">{title}</p>}
      {loading ? (<ComplexLoader size="small" />) : (<h5 className={`${children ? 'hidden' : ''} mb-2 ${textSize} text-white`}>{stat}</h5>)}
    </div>
  </div>
}