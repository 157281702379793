import { stringFromBuffer } from "./formatters";

import { goannaAssets } from './goannaAssets.js'

import { mvzAssets } from './mvzAssets.js'

import { goannaAssetID } from 'Context/walletProvider'

function isArrayFilled(obj) {
  return Array.isArray(obj) && obj.length > 0;
}
function findUnitName(asset, unitNames) {
  try {
    const unitName = asset['params']['unit-name'] || '';
    return unitNames.some(prefix => unitName.startsWith(prefix));
  } catch (error) {
    console.error('findUnitName ', error.message);
    return false;
  }
}
function isGoanna(asset) {
  const assetID = asset['asset-id'] ? asset['asset-id'] : asset['assetId']
  return goannaAssets.some(id => assetID === id && obj.amount === 1)
}
function isMvz(asset) {
  const assetID = asset['asset-id'] ? asset['asset-id'] : asset['assetId']
  return mvzAssets.some(id => assetID === id && obj.amount === 1)
}
function filterAssets(filter, assets, unitNames = []) {
  try {
    switch (filter) {
      case 'isGoanna':
        return assets.some(obj =>
          goannaAssets.some(id => obj['asset-id'] === id && obj.amount === 1)
        );
      case 'isMvZ':
        return assets.some(obj =>
          mvzAssets.some(id => obj['asset-id'] === id && obj.amount === 1)
        );
      case 'hasBalance':
        return assets.filter(asset => asset.amount > 0);
      case 'collection':
        if (!isArrayFilled(unitNames)) return assets;

        return assets.filter(asset => findUnitName(asset, unitNames));
      case 'hasGoannaToken':
        return assets.some(asset => asset['asset-id'] === goannaAssetID)
      default:
        return assets;
    }
  } catch (error) {
    console.error('filterAssets ', error.message);
    return assets;
  }
}
function filterTransactions(filter, transactions) {
  try {
    switch (filter) {
      case 'optin':
        return transactions.filter(
          transaction =>
            transaction['application-transaction']['on-completion'] == 'optin'
        );
      default:
        return transactions;
    }
  } catch (error) {
    console.error('filterTransactions ', error.message);
    return transactions;
  }
}
function findAppState(globalState, key) {
  try {
    return globalState.find(value => {
      return stringFromBuffer(value.key) === key;
    });
  } catch (error) {
    console.error('findAppState ', error.message);
    return null;
  }
}
function findStringAppStateValue(globalState, key) {
  try {
    return globalState.find(state => state.key === key).value
  } catch (error) {
    return undefined
  }
}
function filterApp(filter, app) {
  try {
    switch (filter) {
      case 'global-state':
        return app.params['global-state'];
      case 'global-state-app-id':
        return {
          globalState: app.params['global-state'],
          id: app.id,
          creator: app.params.creator
        };
      case 'global-state-approval':
        return {
          approval: app.params['approval-program'],
          globalState: app.params['global-state'],
          id: app.id,
          creator: app.params.creator
        };
      case 'local-state':
        return app.params['local-state'];
      default:
        return app;
    }
  } catch (error) {
    console.error('filterApp ', error.message);
    return app;
  }
}
function filterAccount(filter, account) {
  try {
    switch (filter) {
      case 'apps-local-state':
        return account['apps-local-state'];
      case 'assets':
        return account['assets'];
      default:
        return account;
    }
  } catch (error) {
    console.error('filterAccount ', error.message);
    return account;
  }
}
function getTraits(listings) {
  try {
    return listings.map(listing => {
      return listing.traits?.map(trait => {
        trait['price'] = listing['price'];
        delete trait.total;
        delete trait.uniqueness;
        return trait;
      });
    });
  } catch (error) {
    console.error('getTraits: ', error.message);
    return [];
  }
}
const filterUniqueCollections = (collections, collectionList) => {
  // Ensure collectionList is unique by filtering out duplicates
  const uniqueCollection = collectionList.filter((value, index, self) => {
    return self.indexOf(value) === index; // Only keep first occurrence of each value
  });

  // Filter collections based on whether element.index exists in uniqueCollection
  const filteredCollections = collections.filter((element) => {
    const included = uniqueCollection.includes(element.index);
    return included;
  });

  // Ensure filteredCollections are unique by filtering out duplicates
  const uniqueFilteredCollections = filteredCollections.filter((value, index, self) => {
    return self.findIndex(v => v.index === value.index) === index;
  });

  return uniqueFilteredCollections;
};
function filterWithGlobalState(apps, key, val) {
  return apps.filter(app => {
    if (!'globalState' in app.params) {
      return false
    }
    const globalState = app.params.globalState
    if (!globalState) return false
    const keyState = globalState.find(state => state.key == key)
    if (!keyState) return false
    return keyState.value == val
  })
}
function filterWithGlobalDate(apps, key) {
  return apps.filter(app => {
    if (!'globalState' in app.params) {
      return false
    }
    const globalState = app.params.globalState
    if (!globalState) return false
    const keyState = globalState.find(state => state.key == key)
    if (!keyState) return false
    return true
  })
}
function filterWithProgramCode(apps, code) {
  return apps.filter(app => {
    if (!'approvalProgram' in app.params) {
      return false
    }
    return app.params.approvalProgram == code
  })
}

class DateFilterUtil {
  /**
   * Gets the range of months from current date going back specified number of months
   */
  static getMonthRanges(options) {
    const {
      months,
      includeCurrentMonth = true
    } = options;

    const ranges = [];
    const currentDate = new Date();

    // If not including current month, move to end of last month
    if (!includeCurrentMonth) {
      currentDate.setDate(0);
    }

    for (let i = 0; i < months; i++) {
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1; // Adding 1 since getMonth() returns 0-11

      // Create start date (1st of month)
      const start = new Date(year, month - 1, 1);

      // Create end date (last day of month)
      const end = new Date(year, month, 0, 23, 59, 59, 999);

      ranges.push({
        start,
        end,
        month,
        year
      });

      // Move to previous month
      currentDate.setDate(0); // Moving to last day of previous month
    }

    return ranges;
  }

  /**
   * Filters objects based on Unix timestamp within specified months range
   */
  static filterByMonthRange(
    objects,
    options
  ) {
    const {
      dateField = 'date',
      months,
      includeCurrentMonth = true
    } = options;

    // Get month ranges
    const ranges = this.getMonthRanges({ months, includeCurrentMonth });

    // Initialize result object with empty arrays for each month
    const result = {};
    ranges.forEach(range => {
      result[`${range.year}-${String(range.month).padStart(2, '0')}`] = [];
    });

    // Filter objects into appropriate months
    objects.forEach(obj => {
      // Convert Unix timestamp (seconds) to Date
      const unixDate = findStringAppStateValue(obj['params']['globalState'], dateField)
      const date = new Date(unixDate * 1000);
      ranges.forEach(range => {
        if (date >= range.start && date <= range.end) {
          const key = `${range.year}-${String(range.month).padStart(2, '0')}`;
          result[key].push(obj);
        }
      });
    });

    return result;
  }
  /**
   * Filters objects based on Unix timestamp within specified months range
   */
  static filterByMonthRangeQ(
    objects,
    options
  ) {
    const {
      dateField = 'date',
      months,
      includeCurrentMonth = true
    } = options;
    // Get month ranges
    const ranges = this.getMonthRanges({ months, includeCurrentMonth });

    // Initialize result object with empty arrays for each month
    const result = {};
    ranges.forEach(range => {
      result[`${range.year}-${String(range.month).padStart(2, '0')}`] = [];
    });

    // Filter objects into appropriate months
    objects.forEach(obj => {
      // Convert Unix timestamp (seconds) to Date
      const unixDate = obj[dateField]
      const date = new Date(unixDate * 1000);
      ranges.forEach(range => {
        if (date >= range.start && date <= range.end) {
          const key = `${range.year}-${String(range.month).padStart(2, '0')}`;
          result[key].push(obj);
        }
      });
    });

    return result;
  }

  /**
   * Gets month label in various formats
   * format: 'short' | 'long' | 'numeric'
   */
  static getMonthLabel(year, month, format = 'long') {
    const date = new Date(year, month - 1, 1);

    switch (format) {
      case 'short':
        return date.toLocaleString('en-US', { month: 'short' });
      case 'numeric':
        return String(month).padStart(2, '0');
      case 'long':
      default:
        return date.toLocaleString('en-US', { month: 'long' });
    }
  }

  /**
   * Summarizes data by month with custom reducer
   */
  static summarizeByMonth(
    filteredData,
    reducer,
    initialValue
  ) {
    const summary = {};

    Object.entries(filteredData).forEach(([month, items]) => {
      summary[month] = reducer(initialValue, items);
    });

    return summary;
  }
}
export {
  filterAssets,
  filterTransactions,
  findAppState,
  filterApp,
  filterAccount,
  getTraits,
  findUnitName,
  filterUniqueCollections,
  filterWithGlobalState,
  filterWithProgramCode,
  findStringAppStateValue,
  DateFilterUtil,
  filterWithGlobalDate
};
