import React, { useRef, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { SHOW_CONNECT_MODAL } from 'Root/services/constants/ActionTypes';

import { closeModal, connectWallet } from 'Root/services/actions/actions';

import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'

import { shortenAddress } from 'Root/helpers/shortenAddress';

import { disconnectWallet } from 'Root/services/actions/actions';

import { useWallet } from '@txnlab/use-wallet';

import useTheme from 'Hooks/useTheme';

import { CircleArrowOutUpRight, CircleX } from 'lucide-react';

const plans = [
  { name: 'Startup', ram: '12GB', cpus: '6 CPUs', disk: '256GB SSD disk' },
  { name: 'Business', ram: '16GB', cpus: '8 CPUs', disk: '512GB SSD disk' },
  { name: 'Enterprise', ram: '32GB', cpus: '12 CPUs', disk: '1TB SSD disk' },
]

const ConnectModal = ({ connectModal, closeModal }) => {
  const { providers } = useWallet();
  const [selected, setSelected] = useState(plans[0])
  let [isOpen, setIsOpen] = useState(true)
  const { getIsDark } = useTheme()

  /**
  * Copies the users wallet to the clipboard.
  * @param {string} address Users Wallet Address.
  */
  function copyAddress(address) {
    navigator.clipboard.writeText(address);
    document.querySelector('.connect-button-wrapper p').textContent = 'Copied';
    setTimeout(function () {
      if (document.querySelector('.connect-button-wrapper p')) {
        document.querySelector('.connect-button-wrapper p').textContent = shortenAddress(address, 10);
      }
    }, 1000);
  }

  const modalRef = useRef();

  const keyPress = useCallback((e) => {
    if (e.key === 'Escape') {
      close()
    }
  }, [closeModal]);

  useEffect(() => {
    document.addEventListener('keydown', keyPress);
    return () => document.removeEventListener('keydown', keyPress);
  });

  function open() {
    setIsOpen(true)
  }

  function close() {
    setIsOpen(false)
    closeModal(SHOW_CONNECT_MODAL)
  }
  useEffect(() => {
    if (connectModal && connectModal.type === SHOW_CONNECT_MODAL) {
      open()
    }
  }, [connectModal])

  if (!connectModal) {
    return null;
  }

  return (
    <Dialog ref={modalRef} open={isOpen} as="div" className="relative z-50 focus:outline-none" onClose={close} >
      <DialogBackdrop className="fixed inset-0 bg-black/30 backdrop-blur" />
      <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-2 md:p-20">
          <DialogPanel
            transition
            className="w-full max-w-md rounded-xl bg-white/5 p-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
          >
            <DialogTitle as="div" className="flex text-dark-text flex-row justify-between items-center mb-4">
              <h6 className='flex flex-col' id='lendModalLabel'>
                {/* <small className='d-block light-weight text-brand'>Lend NFT:</small> */}
                {"Connect Your Wallet"}
              </h6>
              <button type='button' className='w-6 h-6 flex items-center align-center justify-center' onClick={close}>
                <CircleX color={getIsDark() ? '#F114FC' : '#fccc2d'} />
              </button>
            </DialogTitle>
            <div className="mx-auto w-full max-w-md">
              <div by="name" value={selected} onChange={setSelected} aria-label="Server size" className="space-y-2">
                {providers?.map((provider) => (
                  <div
                    key={provider.metadata.id}
                    value={provider.metadata.name}
                    className="group relative flex cursor-pointer rounded-lg bg-white/5 py-4 px-5 text-white shadow-md transition focus:outline-none hover:outline-1 hover:outline-white data-[checked]:bg-white/10 w-full"
                  >
                    <div className="flex w-full items-center justify-between">
                      <div className="flex text-sm/6 w-1/2 justify-start items-center">
                        <img
                          width={30}
                          height={30}
                          className='rounded-full mr-3'
                          alt={`${provider.metadata.name} icon`}
                          src={provider.metadata.icon}
                        />
                        <p className="font-semibold text-white">{provider.metadata.name}</p>
                      </div>
                      <div className="flex gap-2 text-white/50 w-1/2 justify-end">
                        {provider.isConnected && !provider.isActive && <button onClick={provider.connect} disabled={provider.isConnected}
                          className='rounded-tab border border-white/5 bg-white/5 p-1 px-4 text-xs/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 cursor-pointer disabled:bg-black/5 hover:bg-black/5'
                        >
                          Set Active
                        </button>}
                        {!provider.isConnected && <button onClick={provider.connect} disabled={provider.isConnected}
                          className='rounded-tab border border-white/5 bg-white/5 p-1 px-4 text-xs/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 cursor-pointer disabled:bg-black/5 hover:bg-black/5'
                        >
                          Connect
                        </button>}
                        {provider.isConnected && <button onClick={provider.disconnect}
                          className='rounded-tab border border-white/5 bg-white/5 p-1 px-4 text-xs/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 cursor-pointer disabled:bg-black/5 hover:bg-black/5'
                        >
                          Disconnect
                        </button>}
                      </div>
                      <CircleArrowOutUpRight className="size-6 opacity-0 transition group-data-[checked]:opacity-100" />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  connectModal: state.connectModal,
  address: state.connect?.address,
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: (id) => dispatch(closeModal(id)),
  connectWallet: (address) => dispatch(connectWallet(address)),
  disconnectWallet: () => dispatch(disconnectWallet()),
});

ConnectModal.propTypes = {
  connectModal: PropTypes.object,
  closeModal: PropTypes.func,
  address: PropTypes.string,
  disconnectWallet: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectModal);
