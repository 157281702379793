import { CloseButton, MenuButton, MenuItem, MenuItems, Popover, PopoverButton, Menu, PopoverPanel } from '@headlessui/react'
import { Link, useLocation } from 'react-router-dom';
import { useDeviceType } from 'Hooks/useDeviceTypeHook';
import { MenuIcon } from 'lucide-react';
export default function NavLinks() {
  const { isNavMobile } = useDeviceType()
  return (
    <div className="flex gap-8 justify-center">
      {
        isNavMobile ?
          (<MobileLinkMenu />)
          :
          (<>
            <LinkPopOver title='Bank' link='analytics' />
            <LinkPopOver title='Account' link='Account' />
            <LinkPopOver title='Pawn Shop' link='pawnshop' />
            <LinkPopOver title='FAQ' link='faq' />
          </>)
      }
      {/*  */}
    </div>
  )
}

function LinkPopOver({ title, link }) {
  const location = useLocation();
  return <Popover >
    {location.pathname === `/${link}` ?
      (<Link data-active to={`/${link}`} className="block text-sm/6 font-semibold text-white/50 focus:outline-none data-[active]:text-white data-[hover]:text-white data-[focus]:outline-1 data-[focus]:outline-white">
        {title}
      </Link>) :
      <Link to={`/${link}`} className="block text-sm/6 font-semibold text-white/50 focus:outline-none data-[active]:text-white data-[hover]:text-white data-[focus]:outline-1 data-[focus]:outline-white">
        {title}
      </Link>
    }

  </Popover>
}
function MobileLinkPopOver() {

  const location = useLocation();
  const isActive = (path) => {
    return `/${path}` === location.pathname
  }
  return <Popover >
    <PopoverButton className="block text-sm/6 font-semibold text-white/50 focus:outline-none data-[active]:text-white data-[hover]:text-white data-[focus]:outline-1 data-[focus]:outline-white">
      <Menu className='text-white/50 cursor-pointer hover:text-white/80' />
    </PopoverButton>

    <PopoverPanel
      transition
      anchor="bottom"
      className="mt-6 data-[open]:bg-dark-table/50 backdrop-blur-2xl divide-y divide-white/5 rounded-xl bg-white/5 text-sm/6 transition duration-200 ease-in-out [--anchor-gap:var(--spacing-5)] data-[closed]:-translate-y-1 data-[closed]:opacity-0"
    >
      <div className="p-3">
        <CloseButton as={PopOverLink} link={'analytics'} isActive={isActive} label={'Bank'} />
        <CloseButton as={PopOverLink} link={'account'} isActive={isActive} label={'Account'} />
        <CloseButton as={PopOverLink} link={'pawnshop'} isActive={isActive} label={'Pawn Shop'} />
        <CloseButton as={PopOverLink} link={'faq'} isActive={isActive} label={'FAQ'} />
      </div>

    </PopoverPanel>
  </Popover>
}

function MobileLinkMenu() {
  const location = useLocation()
  const isActive = (path) => {
    return `/${path}` === location.pathname
  }

  return (
    <Menu as="div" className="text-white/50 cursor-pointer hover:text-white/80 bg-dark-table">
      {({ open, close }) => (  // Add close function from render prop
        <>
          <MenuButton className="block text-sm/6 font-semibold text-white/50 focus:outline-none data-[active]:text-white data-[hover]:text-white data-[focus]:outline-1 data-[focus]:outline-white">
            <MenuIcon className='text-white/50 cursor-pointer hover:text-white/80' />
          </MenuButton>

          {/* Using Transition from headlessui if needed */}
          <MenuItems className={`absolute left-12 mt-6 ${open ? 'bg-dark-table/90 backdrop-blur-2xl' : ''} divide-y divide-white/5 rounded-xl bg-white/5 text-sm/6 transition duration-200 ease-in-out [--anchor-gap:var(--spacing-5)] ${!open ? '-translate-y-1 opacity-0' : ''}`}>
            <div className="p-3">
              <MenuItem className={`hover:bg-white`}>
                {({ focus }) => (
                  <MenuLink
                    link="analytics"
                    isActive={isActive}
                    label="Bank"
                    active={focus}
                    onClick={close}
                  />
                )}
              </MenuItem>

              <MenuItem>
                {({ focus }) => (
                  <MenuLink
                    link="account"
                    isActive={isActive}
                    label="Account"
                    active={focus}
                    onClick={close}
                  />
                )}
              </MenuItem>
              <MenuItem>
                {({ focus }) => (
                  <MenuLink
                    link="pawnshop"
                    isActive={isActive}
                    label="Pawn Shop"
                    active={focus}
                    onClick={close}
                  />
                )}
              </MenuItem>

              <MenuItem>
                {({ focus }) => (
                  <MenuLink
                    link="faq"
                    isActive={isActive}
                    label="FAQ"
                    active={focus}
                    onClick={close}
                  />
                )}
              </MenuItem>
            </div>
          </MenuItems>
        </>
      )}
    </Menu>
  )
}

function MenuLink({ link, isActive, label, active, onClick }) {
  return (
    <Link
      to={link}
      onClick={onClick}
      className={`hover:bg-white/5
        ${isActive(link) ? 'bg-white/10' : ''} 
        ${active ? 'bg-white/5' : ''}
        block rounded-lg py-2 px-3 transition
      `}
    >
      <p className="font-semibold text-white">{label}</p>
      <p className="text-white/50 opacity-0">Keep track of your growth</p>
    </Link>
  )
}
function PopOverLink({ link, isActive, label }) {
  return <Link to={link} className={`${isActive(link) ? 'bg-white/5' : ''} block rounded-lg py-2 px-3 transition hover:bg-white/5`} >
    <p className="font-semibold text-white">{label}</p>
    <p className="text-white/50 opacity-0">Keep track of your growth</p>
  </Link>
}