/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateNFT = /* GraphQL */ `
  subscription OnCreateNFT($filter: ModelSubscriptionNFTFilterInput) {
    onCreateNFT(filter: $filter) {
      id
      name
      unitName
      image
      collection
      traits
      rarityScore
      rank
      arc19
      arc69
      creator
      tradeable
      collectionID
      collectionInfo {
        id
        collection
        loanAmount
        unitNamePrefix
        loanAPR
        loanLength
        rewardAmount
        hasRewardToken
        tokenASA
        tokenDecimal
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      loanHistory {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateNFT = /* GraphQL */ `
  subscription OnUpdateNFT($filter: ModelSubscriptionNFTFilterInput) {
    onUpdateNFT(filter: $filter) {
      id
      name
      unitName
      image
      collection
      traits
      rarityScore
      rank
      arc19
      arc69
      creator
      tradeable
      collectionID
      collectionInfo {
        id
        collection
        loanAmount
        unitNamePrefix
        loanAPR
        loanLength
        rewardAmount
        hasRewardToken
        tokenASA
        tokenDecimal
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      loanHistory {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteNFT = /* GraphQL */ `
  subscription OnDeleteNFT($filter: ModelSubscriptionNFTFilterInput) {
    onDeleteNFT(filter: $filter) {
      id
      name
      unitName
      image
      collection
      traits
      rarityScore
      rank
      arc19
      arc69
      creator
      tradeable
      collectionID
      collectionInfo {
        id
        collection
        loanAmount
        unitNamePrefix
        loanAPR
        loanLength
        rewardAmount
        hasRewardToken
        tokenASA
        tokenDecimal
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      loanHistory {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAPPS = /* GraphQL */ `
  subscription OnCreateAPPS($filter: ModelSubscriptionAPPSFilterInput) {
    onCreateAPPS(filter: $filter) {
      id
      creator
      global_lend_status
      global_lend_amount
      global_lend_time
      global_lend_asset
      global_lend_paid
      global_lend_payback
      global_lend_date
      global_lend_address
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAPPS = /* GraphQL */ `
  subscription OnUpdateAPPS($filter: ModelSubscriptionAPPSFilterInput) {
    onUpdateAPPS(filter: $filter) {
      id
      creator
      global_lend_status
      global_lend_amount
      global_lend_time
      global_lend_asset
      global_lend_paid
      global_lend_payback
      global_lend_date
      global_lend_address
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAPPS = /* GraphQL */ `
  subscription OnDeleteAPPS($filter: ModelSubscriptionAPPSFilterInput) {
    onDeleteAPPS(filter: $filter) {
      id
      creator
      global_lend_status
      global_lend_amount
      global_lend_time
      global_lend_asset
      global_lend_paid
      global_lend_payback
      global_lend_date
      global_lend_address
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateCOLLECTION = /* GraphQL */ `
  subscription OnCreateCOLLECTION(
    $filter: ModelSubscriptionCOLLECTIONFilterInput
  ) {
    onCreateCOLLECTION(filter: $filter) {
      id
      collection
      loanAmount
      unitNamePrefix
      loanAPR
      loanLength
      rewardAmount
      hasRewardToken
      tokenASA
      tokenDecimal
      nfts {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateCOLLECTION = /* GraphQL */ `
  subscription OnUpdateCOLLECTION(
    $filter: ModelSubscriptionCOLLECTIONFilterInput
  ) {
    onUpdateCOLLECTION(filter: $filter) {
      id
      collection
      loanAmount
      unitNamePrefix
      loanAPR
      loanLength
      rewardAmount
      hasRewardToken
      tokenASA
      tokenDecimal
      nfts {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteCOLLECTION = /* GraphQL */ `
  subscription OnDeleteCOLLECTION(
    $filter: ModelSubscriptionCOLLECTIONFilterInput
  ) {
    onDeleteCOLLECTION(filter: $filter) {
      id
      collection
      loanAmount
      unitNamePrefix
      loanAPR
      loanLength
      rewardAmount
      hasRewardToken
      tokenASA
      tokenDecimal
      nfts {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateLOAN = /* GraphQL */ `
  subscription OnCreateLOAN($filter: ModelSubscriptionLOANFilterInput) {
    onCreateLOAN(filter: $filter) {
      id
      nftID
      nft {
        id
        name
        unitName
        image
        collection
        traits
        rarityScore
        rank
        arc19
        arc69
        creator
        tradeable
        collectionID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      length
      lender
      amount
      paid
      goannaPaid
      datePaid
      tokenASA
      tokenDecimal
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateLOAN = /* GraphQL */ `
  subscription OnUpdateLOAN($filter: ModelSubscriptionLOANFilterInput) {
    onUpdateLOAN(filter: $filter) {
      id
      nftID
      nft {
        id
        name
        unitName
        image
        collection
        traits
        rarityScore
        rank
        arc19
        arc69
        creator
        tradeable
        collectionID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      length
      lender
      amount
      paid
      goannaPaid
      datePaid
      tokenASA
      tokenDecimal
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteLOAN = /* GraphQL */ `
  subscription OnDeleteLOAN($filter: ModelSubscriptionLOANFilterInput) {
    onDeleteLOAN(filter: $filter) {
      id
      nftID
      nft {
        id
        name
        unitName
        image
        collection
        traits
        rarityScore
        rank
        arc19
        arc69
        creator
        tradeable
        collectionID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      length
      lender
      amount
      paid
      goannaPaid
      datePaid
      tokenASA
      tokenDecimal
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
