import React from 'react';

export default function ComplexLoader({ size = 'medium', color = 'blue', type = 'spinner' }) {
  const sizeClasses = {
    small: 'w-4 h-4',
    medium: 'w-8 h-8',
    large: 'w-12 h-12'
  };

  const colorClasses = {
    blue: 'border-t-transparent border-dark-brand2 dark:border-mutant-brand2 dark:border-t-transparent',
    red: 'border-red-500 border-t-transparent',
    green: 'border-green-500 border-t-transparent',
    yellow: 'border-yellow-500 border-t-transparent'
  };

  const renderLoader = () => {
    switch (type) {
      case 'spinner':
        return (
          <div className={`
            ${sizeClasses[size]}
            border-2
            ${colorClasses[color]}
            rounded-full
            animate-spin-slow
          `}></div>
        );

      case 'dots':
        return (
          <div className="flex space-x-2">
            {[0, 0.2, 0.4].map((delay, i) => (
              <div
                key={i}
                className={`
                  ${sizeClasses.small}
                  bg-${color}-500
                  rounded-full
                  animate-bounce
                `}
                style={{ animationDelay: `${delay}s` }}
              ></div>
            ))}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="flex items-center justify-center">
      {renderLoader()}
    </div>
  );
};