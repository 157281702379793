/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getNFT = /* GraphQL */ `
  query GetNFT($id: ID!) {
    getNFT(id: $id) {
      id
      name
      unitName
      image
      collection
      traits
      rarityScore
      rank
      arc19
      arc69
      creator
      tradeable
      collectionID
      collectionInfo {
        id
        collection
        loanAmount
        unitNamePrefix
        loanAPR
        loanLength
        rewardAmount
        hasRewardToken
        tokenASA
        tokenDecimal
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      loanHistory {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listNFTS = /* GraphQL */ `
  query ListNFTS(
    $filter: ModelNFTFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNFTS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        unitName
        image
        collection
        traits
        rarityScore
        rank
        arc19
        arc69
        creator
        tradeable
        collectionID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncNFTS = /* GraphQL */ `
  query SyncNFTS(
    $filter: ModelNFTFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNFTS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        unitName
        image
        collection
        traits
        rarityScore
        rank
        arc19
        arc69
        creator
        tradeable
        collectionID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const NFTbyCOLLECTIONID = /* GraphQL */ `
  query NFTbyCOLLECTIONID(
    $collectionID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNFTFilterInput
    $limit: Int
    $nextToken: String
  ) {
    NFTbyCOLLECTIONID(
      collectionID: $collectionID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        unitName
        image
        collection
        traits
        rarityScore
        rank
        arc19
        arc69
        creator
        tradeable
        collectionID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAPPS = /* GraphQL */ `
  query GetAPPS($id: ID!) {
    getAPPS(id: $id) {
      id
      creator
      global_lend_status
      global_lend_amount
      global_lend_time
      global_lend_asset
      global_lend_paid
      global_lend_payback
      global_lend_date
      global_lend_address
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAPPS = /* GraphQL */ `
  query ListAPPS(
    $filter: ModelAPPSFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAPPS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        creator
        global_lend_status
        global_lend_amount
        global_lend_time
        global_lend_asset
        global_lend_paid
        global_lend_payback
        global_lend_date
        global_lend_address
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAPPS = /* GraphQL */ `
  query SyncAPPS(
    $filter: ModelAPPSFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAPPS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        creator
        global_lend_status
        global_lend_amount
        global_lend_time
        global_lend_asset
        global_lend_paid
        global_lend_payback
        global_lend_date
        global_lend_address
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCOLLECTION = /* GraphQL */ `
  query GetCOLLECTION($id: ID!) {
    getCOLLECTION(id: $id) {
      id
      collection
      loanAmount
      unitNamePrefix
      loanAPR
      loanLength
      rewardAmount
      hasRewardToken
      tokenASA
      tokenDecimal
      nfts {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listCOLLECTIONS = /* GraphQL */ `
  query ListCOLLECTIONS(
    $filter: ModelCOLLECTIONFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCOLLECTIONS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        collection
        loanAmount
        unitNamePrefix
        loanAPR
        loanLength
        rewardAmount
        hasRewardToken
        tokenASA
        tokenDecimal
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCOLLECTIONS = /* GraphQL */ `
  query SyncCOLLECTIONS(
    $filter: ModelCOLLECTIONFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCOLLECTIONS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        collection
        loanAmount
        unitNamePrefix
        loanAPR
        loanLength
        rewardAmount
        hasRewardToken
        tokenASA
        tokenDecimal
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLOAN = /* GraphQL */ `
  query GetLOAN($id: ID!) {
    getLOAN(id: $id) {
      id
      nftID
      nft {
        id
        name
        unitName
        image
        collection
        traits
        rarityScore
        rank
        arc19
        arc69
        creator
        tradeable
        collectionID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      length
      lender
      amount
      paid
      goannaPaid
      datePaid
      tokenASA
      tokenDecimal
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listLOANS = /* GraphQL */ `
  query ListLOANS(
    $filter: ModelLOANFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLOANS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        nftID
        length
        lender
        amount
        paid
        goannaPaid
        datePaid
        tokenASA
        tokenDecimal
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLOANS = /* GraphQL */ `
  query SyncLOANS(
    $filter: ModelLOANFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLOANS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        nftID
        length
        lender
        amount
        paid
        goannaPaid
        datePaid
        tokenASA
        tokenDecimal
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const LoanByNftID = /* GraphQL */ `
  query LoanByNftID(
    $nftID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLOANFilterInput
    $limit: Int
    $nextToken: String
  ) {
    LoanByNftID(
      nftID: $nftID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        nftID
        length
        lender
        amount
        paid
        goannaPaid
        datePaid
        tokenASA
        tokenDecimal
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
