import { createContext, useContext, useRef } from 'react';
import { ClientManager } from '@algorandfoundation/algokit-utils/types/client-manager';
import { useDeviceType } from 'Hooks/useDeviceTypeHook';
import {
  PROVIDER_ID,
  WalletProvider,
  useInitializeProviders,
} from '@txnlab/use-wallet';
import algosdk from 'algosdk';
import { CREATOR } from './credentialsProvider';

function getAlgodConfigFromEnv() {
  return {
    server: 'https://mainnet-api.algonode.cloud',
    port: 443,
    token: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',
    network: 'mainnet',
  };
}

const getDynamicDeflyWalletConnect = async () => {
  const DeflyWalletConnect = (await import('@blockshake/defly-connect'))
    .DeflyWalletConnect;
  return DeflyWalletConnect;
};

const getDynamicPeraWalletConnect = async () => {
  const PeraWalletConnect = (await import('@perawallet/connect'))
    .PeraWalletConnect;
  return PeraWalletConnect;
};

const getDynamicDaffiWalletConnect = async () => {
  const DaffiWalletConnect = (await import('@daffiwallet/connect'))
    .DaffiWalletConnect;
  return DaffiWalletConnect;
};

const ClientContext = createContext(undefined);

export default function ClientProvider({ children }) {
  const { isMobile } = useDeviceType()
  const algodConfig = useRef(getAlgodConfigFromEnv());
  const indexer = useRef(ClientManager.getIndexerClient(algodConfig.current));
  const algodClient = useRef(ClientManager.getAlgodClient(algodConfig.current));
  const creator = useRef(CREATOR)

  const providersArray = useRef([
    { id: PROVIDER_ID.PERA, getDynamicClient: getDynamicPeraWalletConnect, clientOptions: { shouldShowSignTxnToast: true } },
    { id: PROVIDER_ID.DAFFI, getDynamicClient: getDynamicDaffiWalletConnect, clientOptions: { shouldShowSignTxnToast: true } },
  ]);

  const walletProviders = useInitializeProviders({
    providers: providersArray.current,
    nodeConfig: {
      network: 'local',
      nodeServer: algodConfig.current.server,
      nodePort: String(algodConfig.current.port),
      nodeToken: String(algodConfig.current.token),
    },
    algosdkStatic: algosdk,
  });

  const value = {
    algodClient: algodClient.current,
    indexer: indexer.current,
    creator: creator.current,
    isMobile
  };

  return (
    <ClientContext.Provider value={value}>
      <WalletProvider value={walletProviders}>
        {children}
      </WalletProvider>
    </ClientContext.Provider>
  )
}

export const useClientContext = () => {
  const context = useContext(ClientContext);
  if (!context) {
    throw new Error('useClientContext must be used within a ClientProvider');
  }
  return context;
};
