import { useQuery, useQueryClient } from '@tanstack/react-query'

import { DateFilterUtil } from '../helpers/utils/filters';

import { getApplicationsGenerator, getMonthlyLoans } from '../services/api/analytics';

import { useClientContext } from '../context/clientProvider';

import { queryKeys } from '../services/api/queries';
import { decodeAppParamsG } from '../helpers/utils/typecast';


export function filterByMonth(applications) {
  try {
    let obj = {}
    const filtered = DateFilterUtil.filterByMonthRangeQ(applications, {
      months: 3,
      dateField: 'global_lend_date'
    });
    Object.keys(filtered).map(key => {
      const loans = [];
      let totalLoans = 0;
      let totalPayable = 0
      let totalPaid = 0
      let totalAssets = []
      const values = filtered[key]
      values.map(v => {
        const amount = v['global_lend_amount']
        const payback = v['global_lend_payback']
        const paid = v['global_lend_paid']
        const status = v['global_lend_status']
        const asset = v['global_lend_asset']
        const lendDate = v['global_lend_date']
        totalLoans += amount
        totalPayable += payback
        totalPaid += paid
        if (status !== 'debt_claimed') {
          totalAssets.push(asset)
        }
        loans.push({
          appId: v.id,
          global_lend_payback: payback,
          global_lend_status: status,
          global_lend_address: v['global_lend_address'],
          global_lend_time: v['global_lend_time'],
          global_lend_amount: amount,
          global_lend_asset: asset,
          global_lend_date: new Date(lendDate * 1000),
          global_lend_paid: v['global_lend_paid'],
        })
      })
      obj[key] = {
        totalLoans,
        loanCount: loans.length,
        loans: loans.sort((a, b) => b.global_lend_date.getTime() - a.global_lend_date.getTime()) // Sort by date descending
      }
      return
    })
    return obj
  } catch (error) {
    console.log("GET MONTHLY LOANS: ", error.message)
    return {
      totalLoans: 0,
      loanCount: 0,
      loans: [],
      error: error instanceof Error ? error.message : 'An unknown error occurred'
    };
  }
}

export function useLoanAnalyticsQuery() {
  const { creator, algodClient } = useClientContext()
  const queryClient = useQueryClient()

  return useQuery({
    queryKey: queryKeys.loanAnalytics.all,
    queryFn: async () => {
      // Get existing data
      const existingData = queryClient.getQueryData(queryKeys.loanAnalytics.all);
      let allApplications = existingData?.allLoans || [];

      // Use generator to fetch applications
      const generator = getApplicationsGenerator(creator);

      try {
        for await (const batch of generator) {
          // Decode and filter the new batch
          const decodedBatch = batch.map(app => decodeAppParamsG(app)).filter(Boolean);

          // Merge with existing applications
          if (existingData) {
            allApplications = mergeLoans(allApplications, decodedBatch);
          } else {
            allApplications = [...allApplications, ...decodedBatch];
          }

          // Sort by creation date
          allApplications.sort((a, b) => b.created_at_round - a.created_at_round);

          // Update query data after each batch
          queryClient.setQueryData(queryKeys.loanAnalytics.all, {
            allLoans: allApplications,
            ...filterByMonth(allApplications)
          });
        }

        // Return final result
        return {
          allLoans: allApplications,
          ...filterByMonth(allApplications)
        };
      } catch (error) {
        console.error("Error fetching loans:", error);
        throw error;
      }
    },
    keepPreviousData: true,
    staleTime: 0,
    refetchType: 'active',
    cacheTime: 3600000,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchInterval: (data) => data ? 600000 : false,
    isPaused: (data) => Boolean(data),
  });
}

// Efficient loan merging function
function mergeLoans(existingLoans = [], newLoans = []) {
  // Create map of existing loans for quick lookup
  const loanMap = new Map(
    existingLoans.map(loan => [loan.id, loan])
  );

  // Update or add new loans
  newLoans.forEach(loan => {
    const existing = loanMap.get(loan.id);
    if (!existing || existing.global_lend_status !== loan.global_lend_status) {
      loanMap.set(loan.id, loan);
    }
  });

  return Array.from(loanMap.values());
}