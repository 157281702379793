import { getAmountToPay } from "../transactions"
import fullPayment from "./fullPayment";
import overDuePayment from "./overdue";
import partialPayment from "./partial";
import { TransactionStates } from "./utils";


export class PaymentError extends Error {
  constructor(message, stage) {
    super(message);
    this.name = 'PaymentError';
    this.stage = stage;
  }
}


export default async function createPayTransaction(nftId, loanId, loanAmount, payAmountIndex, isOverDue, signProps) {
  try {
    const { algodClient, onStateChange = () => { } } = signProps;
    onStateChange(TransactionStates.PREPARING);

    let payAmount = getAmountToPay(payAmountIndex, loanAmount, isOverDue);
    nftId = parseInt(nftId);
    loanId = parseInt(loanId);
    loanAmount = parseFloat(loanAmount);

    const params = await algodClient.getTransactionParams().do();

    if (isOverDue) {
      return await overDuePayment(params, nftId, loanId, payAmount, signProps);
    }
    if (payAmount === loanAmount) {
      return await fullPayment(params, nftId, loanId, payAmount, signProps);
    } else {
      return await partialPayment(params, nftId, loanId, payAmount, signProps);
    }
  } catch (error) {
    throw new PaymentError(
      error.message || 'Failed to create payment transaction',
      TransactionStates.PREPARING
    );
  }
}