import React from 'react';
import { Disclosure, Transition, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { ChevronDownIcon } from 'lucide-react';

const PrivacySection = ({ title, children }) => (
  <Disclosure>
    {({ open }) => (
      <div className="bg-white/5 rounded-lg overflow-hidden mb-2">
        <DisclosureButton className="w-full flex justify-between items-center px-4 py-3 text-left focus:outline-none focus-visible:ring focus-visible:ring-dark-brand">
          <h4 className='text-dark-brand dark:text-mutant-brand font-medium'>{title}</h4>
          <ChevronDownIcon
            className={`${open ? 'transform rotate-180' : ''
              } w-5 h-5 text-dark-brand dark:text-mutant-brand transition-transform duration-200`}
          />
        </DisclosureButton>

        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <DisclosurePanel className="px-4 pb-4">
            {children}
          </DisclosurePanel>
        </Transition>
      </div>
    )}
  </Disclosure>
);

const Privacy = () => {
  document.title = 'Privacy - Shufl';

  return (
    <div className="p-4 rounded-big max-w-screen-lg m-auto bg-transparent z-0">
      {/* Sticky Header */}
      <div className="bg-black/80 backdrop-blur-xl p-4 rounded-t-lg z-10 border-b border-white/10">
        <h1 className="text-lg text-dark-brand dark:text-mutant-brand text-center">Shufl Privacy Policy</h1>
        <h5 className='text-center text-brand light-weight mt-2'>Last Updated: August 24, 2022</h5>
      </div>

      <div className="p-3 md:p-4 lg:p-5 shadow rounded-tab bg-black/10 backdrop-blur-2xl mt-4">
        <p className="text-white mb-6">
          Shufl, Inc. ("Shufl," "us," "we," or "our") provides the www.shufl.app platform (the "Site") to allow our customers to buy and sell art using non-fungible tokens ("NFTs"). This Privacy Policy is designed to help you understand how we collect, use, process, and share your personal information, and to help you understand and exercise your privacy rights.
        </p>

        <PrivacySection title="1. SCOPE">
          <div className="text-white text-sm space-y-4">
            <p>
              This Privacy Policy applies to personal information processed by us, including on our websites and other online or offline offerings. To make this Privacy Policy easier to read, our websites and other offerings are collectively called the "Services." Shufl is the controller for the personal information we process, unless otherwise stated. For information on how to contact us, please refer to "Contact Us" below.
            </p>
          </div>
        </PrivacySection>

        <PrivacySection title="2. PERSONAL INFORMATION WE COLLECT">
          <div className="text-white text-sm space-y-4">
            <p>
              The categories of personal information we collect depend on how you interact with us, our Services, and the requirements of applicable law. We collect information that you provide to us, information we obtain automatically when you use our Services, and information from other sources such as third-party services and organizations, as described below.
            </p>

            <div>
              <h5 className="text-dark-brand dark:text-mutant-brand font-medium mb-2">Information You Provide to Us Directly</h5>
              <p>
                Crypto Wallet information. To use our Services to buy and sell NFTs, you will need to log in with your existing cryptocurrency wallet, such as Phantom, Slope, Sollet, Solflare, Ledger, Solong, or MathWallet. Note that your interactions with these third-party wallet services are subject to each wallet's respective privacy policy, not this Privacy Policy.
              </p>
              <ul className="list-disc pl-6 mt-2 space-y-2">
                <li>
                  <strong>Your Purchases/Know Your Customer ("KYC") information.</strong> If you buy or sell NFTs using our Services, we will record the details of each transaction (such as the owner of the NFTs, any royalties owed, etc.).
                </li>
                <li>
                  <strong>Listing application.</strong> To list your NFTs on Shufl, you will be required to provide us with information about your project.
                </li>
                <li>
                  <strong>Launchpad applications.</strong> If you apply to join our Launchpad program to receive additional minting assistance, we collect information about your project.
                </li>
                <li>
                  <strong>Your Communications with Us.</strong> We may collect personal information when you request information about our Services.
                </li>
                <li>
                  <strong>Interactive Features.</strong> We and others who use our Services may collect personal information that you submit through interactive features.
                </li>
              </ul>
            </div>
          </div>
        </PrivacySection>

        <PrivacySection title="3. HOW WE USE YOUR INFORMATION">
          <div className="text-white text-sm space-y-4">
            <p>
              We use your information for a variety of business purposes, including to provide our Services, for administrative purposes, and to market our products and Services, as described below.
            </p>

            <div>
              <h5 className="text-dark-brand dark:text-mutant-brand font-medium mb-2">Provide Our Services</h5>
              <p>We use your information to fulfill our contract with you and provide you with our Services, such as:</p>
              <ul className="list-disc pl-6 mt-2 space-y-2">
                <li>Managing your information and accounts</li>
                <li>Providing access to certain areas, functionalities, and features of our Services</li>
                <li>Answering requests for customer or technical support</li>
                <li>Communicating with you about your account, activities on our Services, and policy changes</li>
                <li>Processing your financial information and other payment methods for products or Services purchased</li>
              </ul>
            </div>

            <div>
              <h5 className="text-dark-brand dark:text-mutant-brand font-medium mb-2">Administrative Purposes</h5>
              <p>We use your information for our legitimate interests, or to comply with our legal obligations, such as:</p>
              <ul className="list-disc pl-6 mt-2 space-y-2">
                <li>Direct marketing, research and development</li>
                <li>Detecting security incidents, protecting against malicious activity</li>
                <li>Measuring interest and engagement in our Services</li>
                <li>Improving, upgrading, or enhancing our Services</li>
                <li>Developing new products and Services</li>
              </ul>
            </div>
          </div>
        </PrivacySection>

        <PrivacySection title="4. HOW WE DISCLOSE YOUR INFORMATION">
          <div className="text-white text-sm space-y-4">
            <div>
              <h5 className="text-dark-brand dark:text-mutant-brand font-medium mb-2">Disclosures to Provide our Services</h5>
              <p>The categories of third parties with whom we may share your information are described below:</p>
              <ul className="list-disc pl-6 mt-2 space-y-2">
                <li>
                  <strong>Service Providers.</strong> We may share your personal information with our third-party service providers who use that information to help us provide our Services.
                </li>
                <li>
                  <strong>Business Partners.</strong> We may share your personal information with business partners to provide you with a product or service you have requested.
                </li>
                <li>
                  <strong>Other Users/Website Visitors.</strong> Our Services allow you to share your profile and/or User Content with other users/publicly.
                </li>
                <li>
                  <strong>Advertising Partners.</strong> We may share your personal information with third-party advertising partners.
                </li>
              </ul>
            </div>

            <div>
              <h5 className="text-dark-brand dark:text-mutant-brand font-medium mb-2">Disclosures to Protect Us or Others</h5>
              <p>
                We may access, preserve, and disclose any information we store associated with you to external parties if we, in good faith, believe doing so is required or appropriate to comply with law enforcement or national security requests and legal process.
              </p>
            </div>
          </div>
        </PrivacySection>

        <PrivacySection title="5. YOUR PRIVACY CHOICES AND RIGHTS">
          <div className="text-white text-sm space-y-4">
            <p>Your Privacy Choices. The privacy choices you may have about your personal information are determined by applicable law and are described below:</p>

            <ul className="list-disc pl-6 space-y-2">
              <li>
                <strong>Email Communications.</strong> If you receive an unwanted email from us, you can use the unsubscribe link found at the bottom of the email to opt out of receiving future emails.
              </li>
              <li>
                <strong>"Do Not Track."</strong> Do Not Track ("DNT") is a privacy preference that users can set in certain web browsers. Please note that we do not respond to or honor DNT signals or similar mechanisms.
              </li>
              <li>
                <strong>Cookies and Interest-Based Advertising.</strong> You may stop or restrict the placement of Technologies on your device or remove them by adjusting your preferences as your browser or device permits.
              </li>
            </ul>

            <div>
              <h5 className="text-dark-brand dark:text-mutant-brand font-medium mb-2">Your Privacy Rights</h5>
              <p>In accordance with applicable law, you may have the right to:</p>
              <ul className="list-disc pl-6 mt-2 space-y-2">
                <li>Access Personal Information about you</li>
                <li>Request Correction of your personal information</li>
                <li>Request Deletion, Anonymization or Blocking of your personal information</li>
                <li>Request Restriction of or Object to our processing of your personal information</li>
                <li>Withdraw your Consent to our processing of your personal information</li>
              </ul>
            </div>
          </div>
        </PrivacySection>

        <PrivacySection title="6. SECURITY OF YOUR INFORMATION">
          <div className="text-white text-sm space-y-4">
            <p>
              We take steps to ensure that your information is treated securely and in accordance with this Privacy Policy. Unfortunately, no system is 100% secure, and we cannot ensure or warrant the security of any information you provide to us.
            </p>
            <p>
              By using our Services or providing personal information to us, you agree that we may communicate with you electronically regarding security, privacy, and administrative issues relating to your use of our Services.
            </p>
          </div>
        </PrivacySection>

        <PrivacySection title="7. INTERNATIONAL DATA TRANSFERS">
          <div className="text-white text-sm space-y-4">
            <p>
              All information processed by us may be transferred, processed, and stored anywhere in the world, including, but not limited to, the United States or other countries, which may have data protection laws that are different from the laws where you live.
            </p>
            <p>
              We endeavor to safeguard your information consistent with the requirements of applicable laws.
            </p>
          </div>
        </PrivacySection>

        <PrivacySection title="8. RETENTION OF PERSONAL INFORMATION">
          <div className="text-white text-sm space-y-4">
            <p>
              We store the personal information we collect as described in this Privacy Policy for as long as you use our Services, or as necessary to fulfill the purpose(s) for which it was collected, provide our Services, resolve disputes, establish legal defenses, conduct audits, pursue legitimate business purposes, enforce our agreements, and comply with applicable laws.
            </p>
          </div>
        </PrivacySection>

        <PrivacySection title="9. SUPPLEMENTAL NOTICE FOR CALIFORNIA RESIDENTS">
          <div className="text-white text-sm space-y-4">
            <p>
              This Supplemental Notice for California Residents only applies to our processing of personal information that is subject to the California Consumer Privacy Act of 2018 ("CCPA").
            </p>

            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-opacity-10 rounded-xl table bg-dark-table border border-dark-border rounded-tab">
                <thead>
                  <tr className="grid grid-cols-2 text-xs border border-white/5">
                    <th className="text-left text-xs font-medium text-dark-brand dark:text-mutant-brand uppercase tracking-wider p-4">
                      Category of Personal Information
                    </th>
                    <th className="text-left text-xs font-medium text-dark-brand dark:text-mutant-brand uppercase tracking-wider p-4">
                      Category of Third Parties
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="grid grid-cols-2 text-xs border border-white/5">
                    <td className="p-4 text-white">
                      <strong>Identifiers</strong>
                      <p className="text-white/60 mt-1">
                        Name, email address, IP address, account name
                      </p>
                    </td>
                    <td className="p-4 text-white">
                      <ul className="list-disc pl-4">
                        <li>Advertising networks</li>
                        <li>Data analytics providers</li>
                        <li>Service providers</li>
                      </ul>
                    </td>
                  </tr>
                  <tr className="grid grid-cols-2 text-xs border border-white/5">
                    <td className="p-4 text-white">
                      <strong>Commercial Information</strong>
                      <p className="text-white/60 mt-1">
                        Records of products or services purchased
                      </p>
                    </td>
                    <td className="p-4 text-white">
                      <ul className="list-disc pl-4">
                        <li>Service providers</li>
                        <li>Analytics providers</li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </PrivacySection>

        <PrivacySection title="10. SUPPLEMENTAL NOTICE FOR NEVADA RESIDENTS">
          <div className="text-white text-sm space-y-4">
            <p>
              If you are a resident of Nevada, you have the right to opt-out of the sale of certain Personal Information to third parties who intend to license or sell that Personal Information. Please note that we do not currently sell your Personal Information as sales are defined in Nevada Revised Statutes Chapter 603A.
            </p>
          </div>
        </PrivacySection>

        <PrivacySection title="11. CHILDREN'S INFORMATION">
          <div className="text-white text-sm space-y-4">
            <p>
              The Services are not directed to children under 18 (or other age as required by local law), and we do not knowingly collect personal information from children.
            </p>
          </div>
        </PrivacySection>

        <PrivacySection title="12. OTHER PROVISIONS">
          <div className="text-white text-sm space-y-4">
            <div>
              <h5 className="text-dark-brand dark:text-mutant-brand font-medium mb-2">Third-Party Websites/Applications</h5>
              <p>
                The Services may contain links to other websites/applications and other websites/applications may reference or link to our Services. These third-party services are not controlled by us. We encourage our users to read the privacy policies of each website and application with which they interact.
              </p>
            </div>

            <div>
              <h5 className="text-dark-brand dark:text-mutant-brand font-medium mb-2">Supervisory Authority</h5>
              <p>
                If you are located in the European Economic Area, Switzerland, the United Kingdom, or Brazil, you have the right to lodge a complaint with a supervisory authority if you believe our processing of your personal information violates applicable law.
              </p>
            </div>

            <div>
              <h5 className="text-dark-brand dark:text-mutant-brand font-medium mb-2">Changes to our Privacy Policy</h5>
              <p>
                We may revise this Privacy Policy from time to time in our sole discretion. If there are any material changes to this Privacy Policy, we will notify you as required by applicable law. You understand and agree that you will be deemed to have accepted the updated Privacy Policy if you continue to use our Services after the new Privacy Policy takes effect.
              </p>
            </div>
          </div>
        </PrivacySection>

        {/* Contact Section - Always Visible */}
        <div className="bg-white/5 rounded-lg p-6 mt-6">
          <h4 className="text-dark-brand dark:text-mutant-brand font-medium mb-4">13. CONTACT US</h4>
          <div className="text-white text-sm space-y-4">
            <p>
              If you have any questions about our privacy practices or this Privacy Policy, or to exercise your rights as detailed in this Privacy Policy, please contact us at:
            </p>
            <div className="mt-4">
              <p>Attn: Privacy</p>
              <a
                href="mailto:hello@shufl.app"
                className="text-dark-brand dark:text-mutant-brand hover:underline"
              >
                hello@shufl.app
              </a>
            </div>
            <p>
              or write to us at:<br />
              Via España, Delta Bank Building, 6th Floor, Suite 604(D)<br />
              Panama City, Republic of Panama.
            </p>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Privacy;