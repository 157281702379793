
import React from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import { ChevronDownIcon } from 'lucide-react';

const TermsSection = ({ title, children }) => (
  <Disclosure>
    {({ open }) => (
      <div className="bg-white/5 rounded-lg overflow-hidden mb-2">
        <Disclosure.Button className="w-full flex justify-between items-center px-4 py-3 text-left focus:outline-none focus-visible:ring focus-visible:ring-dark-brand">
          <h4 className='text-dark-brand dark:text-mutant-brand font-medium'>{title}</h4>
          <ChevronDownIcon
            className={`${open ? 'transform rotate-180' : ''
              } w-5 h-5 text-dark-brand dark:text-mutant-brand transition-transform duration-200`}
          />
        </Disclosure.Button>

        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Disclosure.Panel className="px-4 pb-4">
            {children}
          </Disclosure.Panel>
        </Transition>
      </div>
    )}
  </Disclosure>
);

const Terms = () => {
  document.title = 'Terms - Shufl';

  return (
    <div className="p-4 rounded-big max-w-screen-lg m-auto bg-transparent z-0">
      {/* Sticky Header */}
      <div className="bg-black/80 backdrop-blur-xl p-4 rounded-t-lg z-10 border-b border-white/10">
        <h1 className="text-lg text-dark-brand dark:text-mutant-brand text-center">Terms of Service</h1>
        <h5 className='text-center text-brand light-weight mt-2'>Last Updated: August 24, 2022</h5>
      </div>

      <div className="p-3 md:p-4 lg:p-5 shadow rounded-tab bg-black/10 backdrop-blur-2xl mt-4">
        {/* Introduction */}
        <div className="text-dark-text text-sm mb-6">
          <p>
            These Terms of Service (the "Terms") govern your use of the website and interface located at www.shufl.app (the "Site") offered by Shufl, Inc., a Panama Corporation operating at Via España, Delta Bank Building, 6th Floor, Suite 604D, Panama City, Republic of Panama ("Shufl," "we," "our"). The Site facilitates the purchase and sale of non-fungible tokens ("NFTs") on decentralized blockchains ("Blockchains") by interacting with certain decentralized cryptographic protocols ("Protocols"), which Shufl does not own or control (collectively the "Services.") You agree to be bound to these Terms by using the Services.
          </p>
          <p className="mt-4 font-bold">
            IMPORTANT NOTICE REGARDING ARBITRATION: BY USING THE SERVICES, YOU ARE AGREEING (WITH LIMITED EXCEPTIONS) TO RESOLVE ANY DISPUTE BETWEEN YOU AND SHUFL THROUGH BINDING, INDIVIDUAL ARBITRATION RATHER THAN IN COURT. PLEASE REVIEW CAREFULLY SECTION 18 "DISPUTE RESOLUTION" BELOW FOR DETAILS REGARDING ARBITRATION.
          </p>
        </div>

        <TermsSection title="1. Agreement to Terms">
          <p className="text-dark-text text-sm">
            Your use of the services constitutes your agreement to be bound by these Terms. If you don't agree to be bound by these Terms, you are not authorized to use the Services.
          </p>
        </TermsSection>

        <TermsSection title="2. Privacy Policy">
          <p className="text-dark-text text-sm">
            Please review our Privacy Policy, which also governs your use of the Services, for information on how we collect, use and share your information.
          </p>
        </TermsSection>

        <TermsSection title="3. Changes to the Terms or Services">
          <p className="text-dark-text text-sm">
            We may update the Terms from time to time at our sole discretion. We may change or discontinue all or any part of the Services, at any time and without notice. If we do, we'll let you know by posting the updated Terms on the Site or other communication. It's important that you review the Terms whenever we update them or you use the Services. If you continue to use the Services after we have posted updated Terms, it means that you accept and agree to the changes. If you don't agree to be bound by the changes, you must not continue to use the Services.
          </p>
        </TermsSection>

        <TermsSection title="4. Who May Use the Services?">
          <div className="space-y-4 text-dark-text text-sm">
            <div>
              <h5 className="font-semibold mb-2">Eligibility</h5>
              <p>
                You may use the Services only if you are 18 years or older and capable of forming a binding contract with Shufl, and not otherwise barred from using the Services under applicable law.
              </p>
            </div>

            <div>
              <h5 className="font-semibold mb-2">Compliance</h5>
              <p>
                The Services are only available to users who reside in a jurisdiction where use of the Services is permitted under applicable law. You certify that you will comply with all applicable laws (e.g., local, state, federal and other laws) when using the Services.
              </p>
            </div>

            <div>
              <h5 className="font-semibold mb-2">Unauthorized Access</h5>
              <p>
                You may not access or utilize the Site and/or Services for the purpose of web crawling, web harvesting, data mining, data extraction, scraping or aggregating information, including without limitation listings and smart contracts available on or through the Site and/or Services, without our prior written consent in our sole discretion.
              </p>
            </div>
          </div>
        </TermsSection>

        <TermsSection title="5. About the Services">
          <div className="space-y-4 text-dark-text text-sm">
            <div>
              <h5 className="font-semibold mb-2">The Shufl Marketplace</h5>
              <p>
                Our Services facilitate interactions with the Protocols and Blockchains to purchase and sell NFTs. Shufl provides the Shufl Marketplace, which is an interface to interact with the Protocols and Blockchains. Shufl does not provide the Protocol. The Services do not include tools that would allow users to create and deploy NFTs.
              </p>
              <ul className="list-disc pl-6 mt-2 space-y-2">
                <li>
                  You may participate in the Services by linking your digital wallet(s) on supported bridge extensions, which allows you to purchase, store, and engage in transactions using Algorand cryptocurrency.
                </li>
                <li className="font-bold">
                  SHUFL MARKETPLACE IS A PLATFORM. WE ARE NOT A BROKER, FINANCIAL INSTITUTION, OR CREDITOR. THE SERVICES ARE AN ADMINISTRATIVE PLATFORM ONLY.
                </li>
                <li className="font-bold">
                  YOU BEAR FULL RESPONSIBILITY FOR VERIFYING THE IDENTITY, LEGITIMACY, AND AUTHENTICITY OF ASSETS YOU PURCHASE THROUGH THE SHUFL MARKETPLACE PLATFORM.
                </li>
              </ul>
            </div>

            <div>
              <h5 className="font-semibold mb-2">Transactions Are Conducted on the Blockchain</h5>
              <p>
                While Shufl offers a marketplace for NFTs, it does not buy, sell or take custody or possession of any NFTs, nor does it act as an agent or custodian for any user of the Services.
              </p>
            </div>

            <div>
              <h5 className="font-semibold mb-2">Terms Applicable to Purchasers and Sellers</h5>
              <p>
                If you are using the Services to purchase NFTs, you are a "Purchaser," and if you are using the Services to sell NFTs, you are a "Seller." If you are either a Purchaser or Seller, you agree to the following additional terms:
              </p>
              <ul className="list-disc pl-6 mt-2 space-y-2">
                <li>
                  <strong>Purchase Terms:</strong> Although the terms of sale for an NFT are displayed on the Shufl Marketplace, all such terms are determined by the Purchasers and Sellers.
                </li>
                <li>
                  <strong>Changes by Creators:</strong> Due to various NFT standards that allow creators to independently change or modify images and/or metadata associated with an NFT, Shufl cannot be held responsible for any changes.
                </li>
                <li>
                  <strong>Costs and Fees:</strong> Transactions on the Shufl Marketplace may be subject to fees that Shufl collects to support the NFT creators and Shufl Marketplace.
                </li>
              </ul>
            </div>

            <div>
              <h5 className="font-semibold mb-2">Terms Applicable to Creators</h5>
              <p>
                If you are a Creator, you agree to the following additional terms:
              </p>
              <ul className="list-disc pl-6 mt-2 space-y-2">
                <li>
                  <strong>Royalties:</strong> If you are a Creator, in each sale by other Sellers following your initial sale of your NFT, you will receive the "Artist Royalty Fee". The standard Artist Royalty Fee is five percent (5%).
                </li>
                <li>
                  <strong>NFT Content:</strong> The Creator shall continue to exclusively own all right, title, and interest in and to the digital art embodied in the NFTs.
                </li>
                <li>
                  <strong>Creator Obligations:</strong> The Creator shall obtain all rights to the NFT Content in connection with the use thereof by Shufl.
                </li>
              </ul>
            </div>
          </div>
        </TermsSection>

        <TermsSection title="6. Feedback">
          <p className="text-dark-text text-sm">
            We appreciate feedback, comments, ideas, proposals and suggestions for improvements to the Services ("Feedback"). If you choose to submit Feedback, you agree that we are free to use it without any restriction or compensation to you.
          </p>
        </TermsSection>

        <TermsSection title="7. Your Content">
          <div className="space-y-4 text-dark-text text-sm">
            <div>
              <h5 className="font-semibold mb-2">Posting Content</h5>
              <p>
                Our Services may allow you to store or share content such as text (in posts or communications with others), files, documents, graphics, images, music, software, audio and video. Anything (other than Feedback) that you post or otherwise make available through the Services is referred to as "User Content".
              </p>
            </div>

            <div>
              <h5 className="font-semibold mb-2">Permissions to Your User Content</h5>
              <p>
                By making any User Content available through the Services, you hereby grant to Shufl a non-exclusive, transferable, worldwide, royalty-free license, with the right to sublicense, to use, copy, modify, create derivative works based upon, distribute, publicly display, and publicly perform your User Content in connection with operating and providing the Services.
              </p>
            </div>
          </div>
        </TermsSection>

        <TermsSection title="8. [RESERVED]">
          <p className="text-dark-text text-sm">
            [Reserved for future use]
          </p>
        </TermsSection>

        <TermsSection title="9. Acceptable Use Policy">
          <div className="space-y-4 text-dark-text text-sm">
            <p>You agree not to do any of the following:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Post, upload, publish, submit or transmit any User Content that infringes third party rights</li>
              <li>Use, display, mirror or frame the Services without Shufl's express written consent</li>
              <li>Access, tamper with, or use non-public areas of the Services</li>
              <li>Attempt to probe, scan or test the vulnerability of any Shufl system or network</li>
              <li>Avoid, bypass, remove, deactivate, impair, descramble or circumvent any technological measure</li>
              <li>Create or list counterfeit items (including any NFTs)</li>
              <li>Engage in wash trading, front running, pump and dump trading, or other deceptive practices</li>
              <li>Use the Services to participate in fundraising for a business</li>
              <li>Fabricate in any way any transaction or process</li>
              <li>Place misleading bids or offers</li>
            </ul>

            <div className="mt-4">
              <p>
                Shufl is not obligated to monitor access to or use of the Services or to review or edit any content. However, we have the right to do so for the purpose of operating the Services, to ensure compliance with these Terms and to comply with applicable law or other legal requirements.
              </p>
            </div>
          </div>
        </TermsSection>

        <TermsSection title="10. Copyright Policy">
          <p className="text-dark-text text-sm">
            Shufl respects copyright law and expects its users to do the same. It is Shufl's policy to terminate in appropriate circumstances users who repeatedly infringe or are believed to be repeatedly infringing the rights of copyright holders.
          </p>
        </TermsSection>

        <TermsSection title="11. Third Party Links">
          <p className="text-dark-text text-sm">
            The Services may allow you to access third-party websites or other resources. We provide access only as a convenience and are not responsible for the content, products or services on or available from those resources or links displayed on such websites.
          </p>
        </TermsSection>

        <TermsSection title="12. Termination">
          <div className="space-y-4 text-dark-text text-sm">
            <p>
              We may suspend or terminate your access to and use of the Services at our sole discretion, at any time and without notice to you. You may disconnect your digital wallet at any time.
            </p>
            <p>
              You acknowledge and agree that we shall have no liability or obligation to you in such an event and that you will not be entitled to a refund of any amounts that you have already paid to us or any third party, to the fullest extent permitted by applicable law.
            </p>
          </div>
        </TermsSection>

        <TermsSection title="13. Warranty Disclaimers">
          <div className="space-y-4 text-dark-text text-sm">
            <p className="font-bold">
              THE SERVICES, ANY CONTENT CONTAINED THEREIN, AND ANY NFTS ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND.
            </p>
            <p>
              SHUFL DOES NOT REPRESENT OR WARRANT THAT:
            </p>
            <ul className="list-disc pl-6 space-y-2">
              <li>THE SERVICES WILL MEET YOUR REQUIREMENTS</li>
              <li>THE SERVICES WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS</li>
              <li>THE RESULTS OF USING THE SERVICES WILL BE ACCURATE, RELIABLE, COMPLETE, LEGAL, OR SAFE</li>
            </ul>
          </div>
        </TermsSection>

        <TermsSection title="14. Assumption of Risk">
          <div className="space-y-4 text-dark-text text-sm">
            <p>You accept and acknowledge:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li>The prices and liquidity of cryptocurrency assets (including NFTs) are extremely volatile</li>
              <li>You are solely responsible for determining what, if any, taxes apply to your transactions</li>
              <li>Our Services do not store, send, or receive cryptocurrency assets</li>
              <li>There are risks associated with using an Internet-based currency</li>
              <li>The regulatory regime governing blockchain technologies, cryptocurrencies, and tokens is uncertain</li>
            </ul>
          </div>
        </TermsSection>

        <TermsSection title="15. Indemnity">
          <div className="space-y-4 text-dark-text text-sm">
            <div>
              <p>You will indemnify, defend (at Shufl's option) and hold Shufl and its officers, directors, employees and agents, harmless from and against any claims, disputes, demands, liabilities, damages, losses, and costs and expenses, including, without limitation, reasonable legal and accounting fees arising out of or in any way connected with:</p>
              <ul className="list-disc pl-6 mt-2 space-y-2">
                <li>Your access to or use of the Services</li>
                <li>Your NFT Content</li>
                <li>Your violation of these Terms</li>
              </ul>
            </div>
          </div>
        </TermsSection>

        <TermsSection title="16. Limitation of Liability">
          <div className="space-y-4 text-dark-text text-sm">
            <div className="p-4 bg-white/5 rounded-lg">
              <p className="font-bold">
                TO THE MAXIMUM EXTENT PERMITTED BY LAW, NEITHER SHUFL NOR ITS SERVICE PROVIDERS WILL BE LIABLE FOR ANY:
              </p>
              <ul className="list-disc pl-6 mt-2 space-y-2">
                <li>Incidental, special, exemplary or consequential damages</li>
                <li>Lost profits, revenues, or savings</li>
                <li>Lost business opportunity</li>
                <li>Loss of data or goodwill</li>
                <li>Service interruption</li>
                <li>Computer damage or system failure</li>
              </ul>
            </div>
          </div>
        </TermsSection>

        <TermsSection title="17. Governing Law">
          <p className="text-dark-text text-sm">
            These Terms and any action related thereto will be governed by the laws of the Republic of Panama. The exclusive jurisdiction for all Disputes that you and Shufl are not required to arbitrate will be in the Civil Court sitting in Panama City, Republic of Panama.
          </p>
        </TermsSection>

        <TermsSection title="18. Dispute Resolution">
          <div className="space-y-4 text-dark-text text-sm">
            <div>
              <h5 className="font-semibold mb-2">Informal Resolution of Disputes</h5>
              <p>
                You and Shufl must first attempt to resolve any dispute informally. Accordingly, neither you nor Shufl may start a formal arbitration proceeding for at least sixty (60) days after one party notifies the other party of a claim in writing.
              </p>
            </div>

            <div>
              <h5 className="font-semibold mb-2">Mandatory Arbitration of Disputes</h5>
              <p className="font-bold">
                We each agree that any Dispute will be resolved solely by binding, individual arbitration and not in a class, representative or consolidated action or proceeding.
              </p>
            </div>

            <div>
              <h5 className="font-semibold mb-2">Class Action Waiver</h5>
              <p className="font-bold">
                YOU AND SHUFL AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.
              </p>
            </div>
          </div>
        </TermsSection>

        <TermsSection title="19. General Terms">
          <div className="space-y-4 text-dark-text text-sm">
            <div>
              <h5 className="font-semibold mb-2">Reservation of Rights</h5>
              <p>
                Shufl and its licensors exclusively own all right, title and interest in and to the Services, including all associated intellectual property rights.
              </p>
            </div>

            <div>
              <h5 className="font-semibold mb-2">Entire Agreement</h5>
              <p>
                These Terms constitute the entire and exclusive understanding and agreement between Shufl and you regarding the Services.
              </p>
            </div>
          </div>
        </TermsSection>

        {/* Contact Section - Always Visible */}
        <div className="bg-white/5 rounded-lg p-6 mt-6">
          <h4 className="text-dark-brand dark:text-mutant-brand font-medium mb-4">20. Contact Information</h4>
          <div className="text-dark-text text-sm space-y-4">
            <p>
              If you have any questions about these Terms or the Services, please contact:
            </p>
            <div className="mt-4">
              <a
                href="mailto:hello@shufl.app"
                className="text-dark-brand dark:text-mutant-brand hover:underline"
              >
                hello@shufl.app
              </a>
              <p className="mt-2">
                Via España, Delta Bank Building, 6th Floor, Suite 604D<br />
                Panama City, Republic of Panama
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Terms;