import { useQuery } from "@tanstack/react-query"
import { getNftsForApps } from "Apis/graphql"
import { queryKeys } from '../services/api/queries'
import { useLoanAnalyticsContext } from "../context/loanAnalyticsProvider"


async function getLoanHistory(signal, ownerLoans) {
  if (!ownerLoans) return []
  let loanHistory = []

  if (signal.aborted) {
    throw new Error('Query cancelled')
  }


  loanHistory = await getNftsForApps(ownerLoans, 'history')
  return loanHistory
}
export default function useLoanHistory(activeAddress) {
  const { allLoans } = useLoanAnalyticsContext()
  const ownerLoans = allLoans?.filter(loan => loan?.global_lend_address === activeAddress) || []
  return useQuery({
    queryKey: queryKeys.loanHistory.byAppIds(activeAddress, ownerLoans),
    queryFn: async ({ signal }) => {
      return await getLoanHistory(signal, ownerLoans)
    },
    gcTime: 0,
    enabled: Boolean(activeAddress && ownerLoans.length > 0),
    staleTime: 0,      // 1 minute
    refetchInterval: false,
    retry: 3,
    retryDelay: (attemptIndex) => Math.min(1000 * Math.pow(2, attemptIndex), 30000),
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
  })
}