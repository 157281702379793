function stringFromBuffer(key, base = "base64") {
  try {
    return Buffer.from(key, "base64").toString();
  } catch (error) {
    console.error("stringFromBuffer ", error.message);
    return undefined;
  }
}
function getAssetId(asset) {
  if ('asset-id' in asset) {
    return Number(asset['asset-id'])
  }
  if ('assetId' in asset) {
    return Number(asset['assetId'])
  }
  if ('index' in asset) {
    return Number(asset['index'])
  }
  return undefined
}

function getCreatedAppsObject(account) {
  if ("created-apps" in account) {
    return account["created-apps"]
  } else {
    return account['createdApps']
  }
}
function getGlobalState(params) {
  if ("global-state" in params) {
    return params["global-state"]
  } else {
    return params['globalState']
  }
}
function getApprovalProgram(params) {
  if ("approval-program" in params) {
    return params["approval-program"]
  } else {
    return params['approvalProgram']
  }
}

function getPublicKey(creator) {
  if (typeof creator === 'string') {
    return creator
  }
  return creator.publicKey
}
function getApplicationIndex(app) {
  if ("application-index" in app) {
    return Number(app['application-index'])
  }
  return Number(app['applicationIndex'])
}
function getClearStateProgram(params) {
  if ("clear-state-program" in params) {
    return params["clear-state-program"]
  } else {
    return params['clearStateProgram']
  }
}

function convertSecondsToUnix(s) {
  return new Date(s * 1000)
}

export {
  stringFromBuffer,
  getAssetId,
  getCreatedAppsObject,
  getGlobalState,
  getApprovalProgram,
  getClearStateProgram,
  getPublicKey,
  getApplicationIndex,
  convertSecondsToUnix
};
