import React, { useEffect, useState, useMemo } from 'react';
import PawnTabGrid, { PawnTabGridWithImages } from '../components/NFTCard/PawnTabGrid';
import useIsSticky from 'Hooks/useIsSticky';
import useOverdueLoans from '../hooks/useOverDueLoans';

export default function PawnShop() {
  document.title = "PawnShop"
  const { tabHeight } = useIsSticky()
  const [allLoans, setAllLoans] = useState([])
  const [tabs, setTabs] = useState([])
  const [tabSelection, setTabSelection] = useState([])
  const [loanFilters, setLoanFilters] = useState({
    lendAmount: 'all',
    lendTime: 'all'
  });
  const [assetFilters, setAssetFilters] = useState({
    loanLength: 'all',
    loanAmount: 'all',
    apr: 'all'
  });
  const { data, isFetching: loading, error } = useOverdueLoans(true, false);

  useEffect(() => {
    if (!data) return
    if (data?.groupedByCollection) {
      setTabs(Object.keys(data.groupedByCollection).map(k => ({
        name: k,
        length: data.groupedByCollection[k].length,
        warning: false
      })))
      setTabSelection(Object.keys(data.groupedByCollection).map(k => data.groupedByCollection[k]))
    }
    setAllLoans(data?.allLoans || [])
  }, [data])

  return (
    <div className="flex justify-center py-4 mx-0 mx-md-4">
      <div className='w-full max-w-screen-lg'>
        <PawnTabGrid
          filteredAssets={[]}
          assetFilters={assetFilters}
          setAssetFilters={setAssetFilters}
          loading={loading}
          setLoanFilters={setLoanFilters}
          loanFilters={loanFilters}
          tabs={tabs}
          tabPanels={tabSelection}
        />
      </div>
    </div>
  )
}