import { createContext, useContext } from 'react';
import { useLoanAnalyticsQuery } from '../hooks/useLoanAnalyticsQuery';
const LoanAnalyticsContext = createContext(undefined);

export default function LoanProvider({ children }) {
  const { data, isFetching, isPending, isError, error } = useLoanAnalyticsQuery()
  const { allLoans = [] } = data || {}
  const value = {
    data, allLoans, isFetching, isPending, isError, error
  };

  return (
    <LoanAnalyticsContext.Provider value={value}>
      {children}
    </LoanAnalyticsContext.Provider>
  )
}

export const useLoanAnalyticsContext = () => {
  const context = useContext(LoanAnalyticsContext);
  if (!context) {
    throw new Error('useLoanAnalyticsContext must be used within a LoanProvider');
  }
  return context;
};
