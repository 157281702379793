const { default: algosdk } = require("algosdk");

function encodeAddress(address) {
  try {
    return algosdk.encodeAddress(address);
  } catch (error) {
    console.error("encodeAddress ", error.message);
  }
}
function encodeAlgoAddress(address) {
  const decodedBytes = new Uint8Array(Buffer.from(address, 'base64'));
  // Now you can check if it's a valid public key (32 bytes in Algorand)
  return algosdk.encodeAddress(decodedBytes);
}
function decodeSignedTransaction(txn) {
  try {
    return algosdk.decodeSignedTransaction(txn);
  } catch (error) {
    console.error("decodeSignedTransaction ", error.message);
  }
}

/**
 * Decode Base64 into ByteArray
 * @param {string} input Base64 to convert to ByteArray
 * @return {byte[]} ByteArray from Base64 string
 * @example
 * base64Decode('Z2xvYmFsX2xlbmRfcGF5YmFjaw==')
   returns Uint8Array(19) [
      103, 108, 111,  98,  97, 108,
      95, 108, 101, 110, 100,  95,
      112,  97, 121,  98,  97,  99,
      107
    ]
 */
function base64Decode(input) {
  return new Uint8Array(
    atob(input)
      .split("")
      .reduce((arr, chr) => arr.concat([chr.charCodeAt(0)]), [])
  );
}

/**
 * 
 * @param {*} input string
 * @returns base64Encoded
 * @example
 * base64Encode('global_lend_address')
 * returns 'Z2xvYmFsX2xlbmRfYWRkcmVzcw=='
 */
function base64Encode(input) {
  const arr = new Uint8Array(Buffer.from(input))
  return btoa(arr.reduce((str, byte) => str + String.fromCharCode(byte), ''));
}

/**
 * 
 * @param {*} input Uint8Array
 * @returns base64
 * @example
 * base64EncodeUint8(new Uint8Array([103, 108, 111, 98, 97, 108, 95, 108, 101, 110, 100, 95, 97, 115, 115, 101, 116]))
 * returns 'Z2xvYmFsX2xlbmRfYXNzZXQ='
 */
function base64EncodeUint8(input) {
  return btoa(input.reduce((str, byte) => str + String.fromCharCode(byte), ''));
}

/**
 * 
 * @param {*} arr base64Decoded
 * @returns String
 * @example
 * stringFromCharCode('Z2xvYmFsX2xlbmRfYWRkcmVzcw==')
 * returns 'global_lend_address'
 */
function stringFromCharCode(str) {
  const base = base64Decode(str)
  return stringFromChar(base)
}

/**
 * 
 * @param {*} base Uint8Array
 * @returns string
 * @example
 * stringFromChar(new Uint8Array([103, 108, 111, 98, 97, 108, 95, 108, 101, 110, 100, 95, 97, 115, 115, 101, 116]))
 * return 'global_lend_asset'
 */
function stringFromChar(base) {
  return String.fromCharCode(...base)
}

/**
 * 
 * @param {*} str string
 * @returns Uint&Array
 * @example
 * bufferFromString('lend')
   returns Uint8Array(4) [ 108, 101, 110, 100 ]
 */
function bufferFromString(str) {
  return new Uint8Array(Buffer.from(str))
}
export { decodeSignedTransaction, encodeAddress, base64Decode, stringFromCharCode, base64Encode, stringFromChar, bufferFromString, base64EncodeUint8, encodeAlgoAddress };
