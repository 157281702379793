import { useQuery } from "@tanstack/react-query";
import { useLocalWallet } from "../context/walletProvider";
import { queryKeys } from "../services/api/queries";
import { useWallet } from "@txnlab/use-wallet";

const fetchNfd = async (address) => {
  try {
    if (!address) return []
    const response = await fetch(`https://api.nf.domains/nfd/address?address=${address}&view=full&limit=1`)
    const data = await response.json()
    const nfdData = data[0]

    if (nfdData.properties.verified.avatar) {
      nfdData.properties.verified.avatar =
        nfdData.properties.verified.avatar.replace('ipfs://',
          'https://images.nf.domains/ipfs/');
    }
    if (nfdData.properties.verified.banner) {
      nfdData.properties.verified.banner =
        nfdData.properties.verified.banner.replace('ipfs://', 'https://ipfs.algonft.tools/ipfs/');
    }
    if (nfdData.properties.userDefined.banner) {
      nfdData.properties.userDefined.banner =
        nfdData.properties.userDefined.banner.replace('ipfs://', 'https://ipfs.algonft.tools/ipfs/');
    }
    return nfdData
  } catch (error) {
    console.log("fetchNfd: ", error.message)
    return []
  }
}

export default function useNfdHook() {
  const { activeAddress } = useWallet()
  return useQuery({
    queryKey: queryKeys.nfd.byAddress(activeAddress),
    queryFn: async () => fetchNfd(activeAddress),
    gcTime: 0,
    enabled: Boolean(!!activeAddress),
    staleTime: 'Infinity',


  })
}