import { useQuery } from '@tanstack/react-query'
import { AlgoAmount } from '@algorandfoundation/algokit-utils/types/amount'
import { goannaAssetID } from 'Context/walletProvider'
import { queryKeys } from '../services/api/queries'


export default function useAccountAssets(algodClient, activeAddress) {

  return useQuery({
    queryKey: queryKeys.accountAssets.byAddress(activeAddress),
    queryFn: async () => {
      const accountInfo = await algodClient
        .accountInformation(activeAddress)
        .do()
      const algoBalance = AlgoAmount.MicroAlgos(accountInfo.amount).algos
      const minBalance = AlgoAmount.MicroAlgos(accountInfo['min-balance']).algos
      const assets = accountInfo.assets.filter(a => a.amount > 0)
      const goannaAssetInfo = accountInfo.assets.find(
        (asset) => asset["asset-id"] === goannaAssetID
      );
      const goannaBalance = goannaAssetInfo ? AlgoAmount.MicroAlgos(goannaAssetInfo.amount).algos : "OPTIN"
      return {
        algoBalance,
        minBalance,
        allAssets: assets || [],
        goannaBalance
      }
    },
    gcTime: 0,
    enabled: Boolean(algodClient && activeAddress),
    staleTime: 0,      // 1 minute
    refetchInterval: 600000, // 2 minutes - ~720 requests per day per user
    retry: 0,
    retryDelay: (attemptIndex) => Math.min(1000 * Math.pow(2, attemptIndex), 30000),
    // refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    refetchType: 'active'
  })
}