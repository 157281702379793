import React from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

const Layout = ({ children }) => {
  return (
    <div className="parent min-w-screen min-h-screen dark:bg-mutant-bg bg-dark-bg-darker">
      {/* Navbar is position sticky, height ~72px */}
      <div className="w-full sticky top-0 h-[72px] z-50 bg-mutant-nav dark:bg-dark-nav dark:backdrop-blur-2xl  shadow-md border border-dark-border">
        <Navbar />
      </div>

      {/* Main content area that fills available space */}
      <main className="flex-1 w-full mx-auto container px-4 pb-6 overflow-y-auto">
        {children}
      </main>

      {/* Footer with fixed height ~40px */}
      <footer className="bg-mutant-nav dark:bg-dark-nav w-full">
        <Footer />
      </footer>
    </div>
  );
};

export default Layout;