import React, { useState } from 'react';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';

import { openConnectModal, connectWallet, disconnectWallet } from 'Root/services/actions/actions';

import ShuflLiquidityMutant from 'Root/images/logo.webp';

import ShuflLiquidity from 'Root/images/logo.webp';

import useTheme from 'Hooks/useTheme';

import { Copy, LogIn, LogOut, Moon, Pencil, Settings, Settings2, SunMoon, Wallet } from 'lucide-react';

import { ButtonTheme } from '../../themes';

import { Switch, Menu, MenuButton, MenuItem, MenuItems, Textarea } from '@headlessui/react'
import { useDeviceType } from 'Hooks/useDeviceTypeHook';
import { useWallet } from '@txnlab/use-wallet';
import { useLocalWallet } from '../../context/walletProvider';
import ComplexLoader from '../helpers/loading';
import { formatCustomCurrency } from '../../helpers';
import NavLinks from './NavLinks';
import useIsSticky from 'Hooks/useIsSticky';
import { Link } from 'react-router-dom';

const Navbar = ({ address, showConnectModal, connectWallet, disconnectWallet }) => {

  const { getIsDark, toggleTheme } = useTheme()
  const [enabled, setEnabled] = useState(getIsDark())
  const { navRef } = useIsSticky();
  const { isMobile, isNavMobile } = useDeviceType()
  return (
    <nav ref={navRef} className="sticky top-0 w-full z-50 relative flex flex-wrap items-center justify-between px-0 py-2 transition-all ease-in duration-250 lg:flex-nowrap lg:justify-start">
      <div className={`grid ${isNavMobile ? 'grid-cols-[20%_10%_70%]' : 'grid-cols-[25%_50%_25%]'} items-center justify-center content-center align-center w-full px-4 py-1 mx-auto`}>
        <Link to={'/'}>
          <img src={getIsDark() ? ShuflLiquidityMutant : ShuflLiquidity} className="img-fluid logo" width="200px" />
          {/* <h1 className="mb-0 text-xl font-bold text-white capitalize">Goanna Lending</h1> */}
        </Link>
        <NavLinks />
        <div className="flex items-center grow sm:mt-0 sm:mr-6 md:mr-0 lg:flex lg:basis-auto justify-end" >
          <ul className="flex flex-row justify-end pl-0 mb-0 list-none md-max:w-full gap-2 md:gap-10">
            <WalletBalance />
            <MobileMenu showConnectModal={showConnectModal} enabled={enabled} getIsDark={getIsDark} setEnabled={setEnabled} toggleTheme={toggleTheme} isMobile={isMobile} />
            <DesktopMenu toggleTheme={toggleTheme} setEnabled={setEnabled} showConnectModal={showConnectModal} isMobile={isMobile} getIsDark={getIsDark} enabled={enabled} />
          </ul>
        </div>
      </div>
    </nav>
  );
};


const mapStateToProps = (state) => ({
  address: state.connect?.address,
});

const mapDispatchToProps = (dispatch) => ({
  showConnectModal: () => dispatch(openConnectModal()),
  connectWallet: (address) => dispatch(connectWallet(address)),
  disconnectWallet: () => dispatch(disconnectWallet()),
});

Navbar.propTypes = {
  address: PropTypes.string,
  showConnectModal: PropTypes.func,
  connectWallet: PropTypes.func,
  disconnectWallet: PropTypes.func,
};

function MobileWalletMenu({ enabled, getIsDark, setEnabled, toggleTheme, showConnectModal }) {
  const { activeAddress } = useWallet()
  return (
    <Menu  >
      <MenuButton className="z-50 inline-flex items-center gap-2 rounded-md bg-white/5 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-white/10 data-[open]:bg-white/10 data-[focus]:outline-1 data-[focus]:outline-white">
        <Settings2 className="size-4" color={getIsDark() ? '#F114FC' : '#fccc2d'} />
      </MenuButton>

      <MenuItems
        transition
        anchor="bottom end"
        className="w-52 mt-2 origin-top-right rounded-xl border border-white/5 bg-dark-table p-1 text-sm/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 z-50"
      >
        <MenuItem className='flex items-end justify-end '>
          <li className="flex items-center">
            <Switch
              checked={enabled}
              onChange={(active) => {
                setEnabled(active)
                toggleTheme()
              }}
              className="group relative flex h-7 w-14 cursor-pointer rounded-full bg-white/10 p-1 transition-colors duration-200 ease-in-out focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white data-[checked]:bg-white/10"
            >
              <span
                aria-hidden="true"
                className="flex items-center pointer-events-none inline-block size-5 translate-x-0 rounded-full ring-0 shadow-lg transition duration-200 ease-in-out group-data-[checked]:translate-x-7"
              >
                {getIsDark() ? <Moon color='#F114FC' /> : <SunMoon color='#fccc2d' />}
              </span>
            </Switch>
          </li>
        </MenuItem>
        <div className="my-1 h-px bg-white/5" />

        <MenuItem>
          {activeAddress ? (
            <button className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10 backdrop-blur z-50" onClick={showConnectModal} >
              <LogOut className="size-4 fill-white/30" />
              Disconnect Wallet
              <kbd className="ml-auto hidden font-sans text-xs text-white/50 group-data-[focus]:inline">⌘D</kbd>
            </button>
          ) : (
            <button className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10 backdrop-blur z-50" onClick={showConnectModal}>
              <LogIn className="size-4 fill-white/30" />
              Connect Wallet
              <kbd className="ml-auto hidden font-sans text-xs text-white/50 group-data-[focus]:inline">⌘D</kbd>
            </button>
          )}
        </MenuItem>

      </MenuItems>
    </Menu>
  )
}



function WalletBalance() {
  const { activeAddress } = useWallet();
  const { goannaBalance, algoBalance, loading } = useLocalWallet();

  const handleCopy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success("Copied!");
    } catch (err) {
      toast.error("Failed to copy");
    }
  };

  return activeAddress && (
    <li className='flex items-center bg-black/10 dark:bg-white/5 border border-black/60 dark:border-white/10 rounded-tab px-4 py-1'>
      <div className='block'>
        {loading && !goannaBalance ? (
          <ComplexLoader
            size="small"
            className='flex items-center bg-black/10 dark:bg-white/5 border border-black/60 dark:border-white/10 rounded-tab px-4 py-1'
          />
        ) : (
          typeof goannaBalance === 'number' ? (
            formatCustomCurrency(goannaBalance, "GOANNA")
          ) : (
            <div className="flex items-center gap-2">
              <p className='hidden sm:block whitespace-nowrap block text-xs text-bold text-dark-text'>
                Opt Into Asset{' '}
                <strong className='block text-sm text-dark-text-brand dark:text-mutant-text-brand'>
                  1387238831
                </strong>
              </p>
              <strong className='sm:hidden text-sm text-dark-text-brand dark:text-mutant-text-brand'>
                1387238831
              </strong>
              <button
                onClick={() => handleCopy('1387238831')}
                className="p-1 hover:bg-black/5 dark:hover:bg-white/5 rounded-full transition-colors"
                aria-label="Copy asset ID"
              >
                <Copy className="h-4 w-4 text-dark-text-brand dark:text-mutant-text-brand" />
              </button>
            </div>
          )
        )}
      </div>
    </li>
  );
}
function DesktopMenu({ showConnectModal, isMobile, getIsDark, enabled, setEnabled, toggleTheme }) {
  return (
    <ul className={`${isMobile ? 'hidden' : 'hidden sm:flex '} flex items-center gap-4`}>
      <li className="flex items-center">
        <button className={`${ButtonTheme('secondary')}`} onClick={showConnectModal}>
          <Wallet color={getIsDark() ? '#F114FC' : '#fccc2d'} />
        </button>
      </li>
      <li className="flex items-center hidden sm:flex">
        <Switch
          checked={enabled}
          onChange={(active) => {
            setEnabled(active)
            toggleTheme()
          }}
          className="group relative flex h-7 w-14 cursor-pointer rounded-full bg-white/10 p-1 transition-colors duration-200 ease-in-out focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white data-[checked]:bg-white/10"
        >
          <span
            aria-hidden="true"
            className="flex items-center pointer-events-none inline-block size-5 translate-x-0 rounded-full ring-0 shadow-lg transition duration-200 ease-in-out group-data-[checked]:translate-x-7"
          >
            {getIsDark() ? <Moon color='#F114FC' /> : <SunMoon color='#fccc2d' />}
          </span>
        </Switch>
      </li>
    </ul>
  )
}
function MobileMenu({ showConnectModal, enabled, getIsDark, setEnabled, toggleTheme, isMobile }) {
  return (
    <li className={`${isMobile ? 'flex' : 'hidden'} items-center`}>
      <MobileWalletMenu showConnectModal={showConnectModal} enabled={enabled} getIsDark={getIsDark} setEnabled={setEnabled} toggleTheme={toggleTheme} />
    </li>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
