import algosdk from 'algosdk';
import { base64Encode } from '../../../helpers/base64';
import { PaymentError } from './createPayTransaction';
import { payLoan } from '..';
import { TransactionStates } from "./utils";
export default async function fullPayment(params, nftId, loanId, payAmount, signProps) {
  const {
    address,
    signTransactions,
    setSignTxn,
    setDisabled,
    setLoading,
    onStateChange = () => { }
  } = signProps;

  try {

    const optInTransaction = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      suggestedParams: {
        ...params,
      },
      from: address,
      to: address,
      assetIndex: nftId,
      amount: 0,
    });

    const payTransaction = algosdk.makeApplicationNoOpTxnFromObject({
      suggestedParams: { ...params },
      from: address,
      appIndex: loanId,
      appArgs: [new Uint8Array(Buffer.from('pay'))],
      foreignAssets: [nftId],
    });

    payTransaction.fee = 3000;

    const fundTransaction = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
      suggestedParams: { ...params },
      from: address,
      to: algosdk.getApplicationAddress(loanId),
      amount: parseInt(algosdk.algosToMicroalgos(payAmount)),
    });

    const txnsToGroup = [optInTransaction, payTransaction, fundTransaction];
    const groupID = algosdk.computeGroupID(txnsToGroup);
    for (let i = 0; i < txnsToGroup.length; i++) txnsToGroup[i].group = groupID;


    // Sign transactions
    onStateChange(TransactionStates.AWAITING_SIGNATURE);
    setSignTxn(true);
    setDisabled(true);
    const encodedGrp = txnsToGroup.map(t => algosdk.encodeUnsignedTransaction(t))
    const transactionsToSend = await signTransactions(encodedGrp);
    setSignTxn(false);

    if (!transactionsToSend) {
      throw new PaymentError('User rejected transaction signing', TransactionStates.AWAITING_SIGNATURE);
    }

    // Send transactions
    onStateChange(TransactionStates.SENDING);
    setLoading(true);

    const decodedTxn = transactionsToSend.map(t => base64Encode(t));
    const result = await payLoan(decodedTxn);

    onStateChange(TransactionStates.CONFIRMED);
    setDisabled(false);
    setLoading(false);
    setSignTxn(false);
    return result;

  } catch (error) {
    // Clean up UI state
    setDisabled(false);
    setLoading(false);
    setSignTxn(false);

    // Throw error with context
    throw new PaymentError(
      error.message || 'Failed to process full payment',
      error.stage || TransactionStates.SENDING
    );
  }
}