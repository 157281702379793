
import { partialPay } from "..";
import { base64Encode } from "../../../helpers/base64";
import { PaymentError } from "./createPayTransaction";
import algosdk from 'algosdk';
import { TransactionStates } from "./utils";
export default async function partialPayment(params, nftId, loanId, payAmount, signProps) {
  const {
    address,
    signTransactions,
    setSignTxn,
    setDisabled,
    setLoading,
    onStateChange = () => { }
  } = signProps;

  try {

    const payTransaction = algosdk.makeApplicationNoOpTxnFromObject({
      suggestedParams: { ...params },
      from: address,
      appIndex: loanId,
      appArgs: [new Uint8Array(Buffer.from('partial_pay'))],
      foreignAssets: [nftId],
    });
    payTransaction.fee = 2000;

    const fundTransaction = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
      suggestedParams: { ...params },
      from: address,
      to: algosdk.getApplicationAddress(loanId),
      amount: parseInt(algosdk.algosToMicroalgos(payAmount)),
    });

    const txnsToGroup = [payTransaction, fundTransaction];
    const groupID = algosdk.computeGroupID(txnsToGroup);
    for (let i = 0; i < txnsToGroup.length; i++) txnsToGroup[i].group = groupID;

    // Sign transactions
    setSignTxn(true);
    setDisabled(true);

    const encodedGrp = txnsToGroup.map(t => algosdk.encodeUnsignedTransaction(t))
    const transactionsToSend = await signTransactions(encodedGrp);
    setSignTxn(false);
    if (!transactionsToSend) {
      throw new PaymentError('User rejected transaction signing', TransactionStates.AWAITING_SIGNATURE);
    }

    // Send transactions
    onStateChange(TransactionStates.SENDING);
    setLoading(true);

    const decodedTxn = transactionsToSend.map(t => base64Encode(t));
    const result = await partialPay(decodedTxn);

    onStateChange(TransactionStates.CONFIRMED);
    setDisabled(false);
    setLoading(false);
    setSignTxn(false);
    return result;

  } catch (error) {
    // Clean up UI state
    setDisabled(false);
    setLoading(false);
    setSignTxn(false);
    throw new PaymentError(
      error.message || 'Failed to process partial payment',
      error.stage || TransactionStates.SENDING
    );
  }
}