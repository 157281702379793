export const getStateMessage = (state) => {
  switch (state) {
    case TransactionStates.PREPARING:
      return "Preparing transaction...";
    case TransactionStates.AWAITING_SIGNATURE:
      return "Please sign the transaction...";
    case TransactionStates.SENDING:
      return "Sending transaction...";
    case TransactionStates.CONFIRMED:
      return "Transaction confirmed!";
    default:
      return "Processing...";
  }
};

export const TransactionStates = {
  PREPARING: 'PREPARING',
  AWAITING_SIGNATURE: 'AWAITING_SIGNATURE',
  SENDING: 'SENDING',
  CONFIRMED: 'CONFIRMED'
};

export const TX_STAGES = {
  SIGNING: 'SIGNING',
  PROCESSING: 'PROCESSING',
  CONFIRMING: 'CONFIRMING',
  COMPLETED: 'COMPLETED'
}