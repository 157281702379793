import { AlgoAmount } from "@algorandfoundation/algokit-utils/types/amount";
import algosdk from "algosdk";
export const FILTERS = {
  assets: {
    loanLength: {
      ranges: [
        { label: 'All', value: 'all' },
        { label: '30 Days', value: 2592000 },
        { label: '60 Days', value: 5184000 },
        { label: '90 Days', value: 7776000 }
      ]
    },
    loanAmount: {
      ranges: [
        { label: 'All', value: 'all' },
        { label: '0-50', min: 0, max: 50 },
        { label: '50-100', min: 50, max: 100 },
        { label: '100+', min: 100, max: Infinity }
      ]
    },
    apr: {
      ranges: [
        { label: 'All', value: 'all' },
        { label: '0-5%', min: 0, max: 5 },
        { label: '5-10%', min: 5, max: 10 },
        { label: '10%+', min: 10, max: Infinity }
      ]
    }
  },
  loans: {
    lendAmount: {
      ranges: [
        { label: 'All', value: 'all' },
        { label: '0-50', min: 0, max: 50 },
        { label: '50-100', min: 50, max: 100 },
        { label: '100+', min: 100, max: Infinity }
      ]
    },
    lendTime: {
      ranges: [
        { label: 'All', value: 'all' },
        { label: '30 Days', value: 2592000 },
        { label: '60 Days', value: 5184000 },
        { label: '90 Days', value: 7776000 }
      ]
    }
  }
};

/**
* Function to change format from 1000 to 1k etc.
* @param {Ojbect} num Number to convert
* @param {Ojbect} digits Digits to include
* @return {boolean} Return formatted string
*/
export function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup.slice().reverse().find(function (item) {
    return num >= item.value;
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : num.toFixed(digits);
}


/**
* Function to show nice date/ time
* @param {number} UNIX_TIMESTAMP - The UNIX timestamp to convert.
* @return {string} The formatted date/time string.
*/
export function timeConverter(UNIX_TIMESTAMP) {
  const a = new Date(UNIX_TIMESTAMP * 1000);
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const year = a.getFullYear();
  const month = months[a.getMonth()];
  const date = a.getDate();
  const hour = a.getHours();
  const min = a.getMinutes();
  const sec = a.getSeconds();
  const time = date + ' ' + month + ' ' + year + ' // ' + hour + ':' + min + ':' + sec;
  return time;
}

export function getLendAmount(globalState) {
  return algosdk.microalgosToAlgos(globalState.find(
    (state) => state.key === "global_lend_amount",
  ).value)
}
export function getLendAmountG(loan) {
  return AlgoAmount.MicroAlgos(loan?.global_lend_amount || 0).algos

}
export function getPayback(globalState) {
  return algosdk.microalgosToAlgos(globalState.find(
    (state) => state.key === "global_lend_payback",
  ).value)
}
export function getPaybackG(loan) {
  return AlgoAmount.MicroAlgos(loan?.global_lend_payback || 0).algos

}
export function getPaid(globalState) {
  return algosdk.microalgosToAlgos(globalState.find(
    (state) => state.key === "global_lend_paid",
  ).value)
}
export function getPaidG(loan) {
  return AlgoAmount.MicroAlgos(loan?.global_lend_paid || 0).algos
}
export function getLendTime(globalState) {
  return globalState.find(
    (state) => state.key === "global_lend_time",
  ).value
}
export function getLendTimeG(loan) {
  return loan?.global_lend_time || 0

}
export function getLendDate(globalState) {
  return globalState.find(
    (state) => state.key === "global_lend_date",
  ).value
}
export function getLendDateG(loan) {
  return loan?.global_lend_date || 0
}
export function calcPrice(globalState) {
  const payback = getPayback(globalState)
  const amount = getPaid(globalState)
  return payback - amount
}
export function calcPriceG(loan) {
  const payback = getPaybackG(loan)
  const amount = getPaidG(loan)
  return payback - amount
}
export function calcEndDate(globalState) {
  const date = getLendDate(globalState)
  const duration = getLendTime(globalState)
  return date + duration
}
export function calcEndDateG(loan) {
  const date = getLendDateG(loan)
  const duration = getLendTimeG(loan)
  return date + duration
}

export function formatCustomCurrency(value, customLabel, locale = 'en-US') {
  // Format the number with Intl.NumberFormat
  const formattedValue = new Intl.NumberFormat(locale, {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);

  // Return HTML markup with number and custom label in separate spans
  return <div className="flex flex-row gap-2">
    <span className="text-dark-text">{formattedValue}</span> <span className='text-dark-brand dark:text-mutant-brand'>{customLabel}</span>
  </div>
}

