import Portal from 'Root/images/portal.jpg';

export default function Nothing({ pt = 48, t = 40, l = 0, r = 0, position = 'absolute' }) {
  return (
    <div className='col-span-full flex justify-center items-center'>
      <div
        className={`portal rounded-3xl overflow-hidden ${position} top-${t} pt-${pt} l-${l} r=${r} opacity-20 sm:opacity-30`}
        style={{
          maxWidth: '400px',
        }}
      >
        <div className={`absolute inset-0 z-10`} />
        <img
          src={Portal}
          className="block w-full relative z-0"
          alt="Portal"
        />
      </div>
    </div>
  )
}