/**
 * Creates a hash from an array of objects based on their key values
 * @param {Array<Object>} objects - Array of objects containing 'key'
 * @returns {string} - Hash value that changes only when keys change
 */
function createHashID(objects, key) {
  // Extract and sort asset IDs to ensure consistent ordering
  if (!objects) return ''
  const assetIds = objects
    .map(obj => obj[key])
    .filter(id => id != null) // Remove null/undefined values
    .sort();

  // If no valid asset IDs, return empty hash
  if (assetIds.length === 0) {
    return '';
  }

  // Join asset IDs and create hash
  const assetString = assetIds.join('|');

  // Create hash using a simple but effective algorithm
  let hash = 0;
  for (let i = 0; i < assetString.length; i++) {
    const char = assetString.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash; // Convert to 32-bit integer
  }

  // Convert to positive hex string
  return Math.abs(hash).toString(16);
}

export const queryKeys = {
  loanAnalytics: {
    all: ['loanAnalytics'],
    subscription: () => [...queryKeys.loanAnalytics.all, 'subscription']
  },
  ovedueLoans: {
    all: ['ovedueLoans'],
    subscription: () => [...queryKeys.ovedueLoans.all, 'subscription'],
    byAppIds: (apps) => {
      const hash = createHashID(apps, 'id')
      return [...queryKeys.ovedueLoans.all, hash]
    }
  },
  accountAssets: {
    all: ['accountAssets'],
    byAddress: (address) => [...queryKeys.accountAssets.all, address]
  },
  eligibleNfts: {
    all: ['eligibleNfts'],
    byAddress: (address) => [...queryKeys.eligibleNfts.all, address],
    byAssetIds: (address, assets) => {
      const hash = createHashID(assets, 'asset-id')
      return [...queryKeys.eligibleNfts.all, address, hash]
    }
  },
  loanHistory: {
    all: ['loanHistory'],
    byAddress: (address) => [...queryKeys.loanHistory.all, address],
    byAppIds: (address, apps) => {
      const hash = createHashID(apps, 'id')
      return [...queryKeys.loanHistory.all, address, hash]
    }
  },
  nfd: {
    all: ['nfd'],
    byAddress: (address) => [...queryKeys.nfd.all, address]
  },
}