import { useQuery, useQueryClient } from "@tanstack/react-query"
import { getNftsForApps } from "Apis/graphql"
import { queryKeys } from '../services/api/queries'
import { useLoanAnalyticsContext } from "../context/loanAnalyticsProvider";

const isLoanOverdue = (loan) => {
  const status = loan.global_lend_status;
  const lendDate = loan.global_lend_date;
  const lendTime = loan.global_lend_time;
  const THREE_DAYS_IN_MS = 3 * 24 * 60 * 60 * 1000; // 3 days in milliseconds

  // Early return if loan is paid or debt is claimed
  if (status === 'paid' || status === 'debt_claimed') return false;

  const expiryTime = (lendDate + lendTime) * 1000;
  const currentTime = Date.now();

  // Check if current time is beyond expiry time + 3 days
  return currentTime >= (expiryTime + THREE_DAYS_IN_MS);
};


async function getLoanHistory(signal, overdueLoans) {
  if (!overdueLoans) return []
  let overdue = []

  if (signal.aborted) {
    throw new Error('Query cancelled')
  }

  overdue = await getNftsForApps(overdueLoans, 'overdue')
  return overdue
}

export default function useOverdueLoans() {
  const { allLoans } = useLoanAnalyticsContext()
  const overdueLoans = allLoans?.filter(isLoanOverdue) || []
  return useQuery({
    queryKey: queryKeys.ovedueLoans.byAppIds(overdueLoans),
    queryFn: async ({ signal }) => {
      return await getLoanHistory(signal, overdueLoans)
    },
    gcTime: 0,
    enabled: Boolean(overdueLoans.length > 0),
    staleTime: 0,
    refetchInterval: false,
    retry: 3,
    retryDelay: (attemptIndex) => Math.min(1000 * Math.pow(2, attemptIndex), 30000),
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
  })
}