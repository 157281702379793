import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Scrolls to top of page on every render.
 */
export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: -100,
      behavior: 'instant',
    });
  }, [pathname]);
}

// Device detection utility
export const getDeviceType = () => {
  // Check if window is defined (for SSR)
  if (typeof window === 'undefined') return 'desktop';

  const userAgent = window.navigator.userAgent.toLowerCase();

  // Regex for mobile devices
  const mobileRegex = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini|mobile|tablet/i;

  // Check screen width for responsive breakpoint
  const isMobileWidth = window.innerWidth <= 640;

  // Return true if either userAgent matches mobile pattern or screen width is mobile
  return mobileRegex.test(userAgent) || isMobileWidth ? 'mobile' : 'desktop';
};

// screens: {
//   'sm': '640px',   // Small devices (e.g. phones)
//   'md': '768px',   // Medium devices (e.g. tablets)
//   'lg': '1024px',  // Large devices (e.g. laptops)
//   'xl': '1280px',  // Extra large devices
//   '2xl': '1536px'  // 2X Extra large devices
// }
// Alternative using only screen size (more reliable for responsive design)
export const isDeviceMobile = () => {
  if (typeof window === 'undefined') return false;
  return window.innerWidth <= 640;
};

export function isNavTypeMobile() {
  return window.innerWidth <= 1000
}
