import { useState, useEffect } from 'react';

export default function useTheme() {
  const [theme, setTheme] = useState(() => {
    const t = getInitialTheme()
    document.documentElement.classList.add(t)
  });
  const [isDark, setIsDark] = useState(() => {
    if (document.documentElement.classList.contains('dark')) return true
    return false
  })
  // Function to toggle between light and dark themes
  function toggleTheme() {
    if (theme === 'dark') {
      setTheme('light');
      document.documentElement.classList.remove('dark');
      localStorage.setItem('theme', 'light');
    } else {
      setTheme('dark');
      document.documentElement.classList.add('dark');
      localStorage.setItem('theme', 'dark');
    }
  }

  // Function to get the initial theme from localStorage or system preference
  function getInitialTheme() {
    const storedTheme = localStorage.getItem('theme');

    if (storedTheme) {
      return storedTheme; // Return stored theme (either 'light' or 'dark')
    } else {
      // If no theme is stored, use system preference
      const systemPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      return systemPrefersDark ? 'dark' : 'light';
    }
  }

  function getIsDark() {
    return document.documentElement.classList.contains('dark')
  }
  // Effect to apply the current theme class to the document
  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.remove('light');
      document.documentElement.classList.add('dark');
      setIsDark(true)
    } else {
      document.documentElement.classList.remove('dark');
      document.documentElement.classList.add('light');
      setIsDark(false)
    }
  }, [theme]);

  return { theme, toggleTheme, isDark, getIsDark };
}
