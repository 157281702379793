// Hook version for React components
import { useState, useEffect } from 'react';
import { getDeviceType, isDeviceMobile, isNavTypeMobile } from '../helpers/scrollToTop';

export const useDeviceType = () => {
  const [deviceType, setDeviceType] = useState(getDeviceType());
  const [isMobile, setIsMobile] = useState(false)
  const [isDMobile, setIsDMobile] = useState(isDeviceMobile())
  const [isNavMobile, setIsNavMobile] = useState(isNavTypeMobile())
  useEffect(() => {
    const handleResize = () => {
      setDeviceType(getDeviceType());
      setIsDMobile(isDeviceMobile())
      setIsNavMobile(isNavTypeMobile())
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!deviceType) return
    setIsMobile(deviceType === 'mobile' || isDMobile)
  }, [deviceType, isDMobile])
  return { deviceType, isMobile, isNavMobile };
};