const goannaAssets = [
  335090720, 398332587, 445445646, 351035082, 342630781, 386755533, 357805558,
  342577575, 398320385, 445442449, 398313581, 445442820, 351036782, 335181136,
  357878647, 342576301, 398307142, 398317819, 328496756, 386679027, 350986326,
  386698707, 326197463, 328559225, 398312573, 328527340, 342617512, 378180154,
  386707457, 350988713, 342579915, 337013855, 398328641, 328523922, 342574076,
  445437910, 386954778, 386941384, 342579388, 435068291, 342634204, 328543527,
  357813060, 357822102, 351165507, 334798386, 404282104, 404284041, 404283294,
  404284741, 404281390, 357829470, 350990941, 357878164, 337012363, 357807254,
  342610860, 370209227, 445447548, 351147149, 398324040, 370201662, 335167490,
  335151590, 435069474, 445448499, 418223077, 328505825, 351147615, 328498815,
  328497556, 328566193, 328503002, 418217057, 357811089, 350986888, 357820948,
  358120725, 398269458, 435071083, 328562778, 435066409, 398270277, 370220151,
  386679558, 335140288, 335155944, 334797672, 351036402, 418226954, 357881145,
  445442004, 445447240, 404288146, 404285472, 404287266, 404286338, 404289065,
  404290245, 418227757, 370175275, 351120479, 445440302, 398318793, 342636903,
  357882255, 398333216, 386954015, 404270194, 404278986, 404278239, 404275773,
  404273247, 404277289, 404271649, 404274994, 404272362, 404271051, 404269571,
  404274138, 342576919, 378157329, 357811513, 435077024, 357820088, 335149637,
  334799499, 445444628, 328526043, 342604022, 351059326, 386703284, 357880107,
  342602925, 335138360, 357828954, 370222671, 328517537, 378189282, 350987324,
  351121162, 435064009, 435075432, 378182338, 350960333, 418136923, 342629720,
  435062845, 418147060, 445440046, 386678024, 342583957, 330598116, 370179699,
  386705461, 357879213, 342592000, 398331199, 398267736, 398268460, 398321353,
  351143875, 342613206, 357807687, 445438349, 398323043, 418148320, 370214171,
  357820613, 378160339, 328540781, 370207486, 418136017, 435062343, 386942977,
  330598680, 435066977, 435065868, 335181726, 351165928, 335121926, 351128646,
  357821399, 386694998, 445446786, 386676057, 357815902, 328502288, 370141226,
  357857972, 378156031, 445437581, 411413044, 386941848, 378182828, 370139978,
  370140425, 418141283, 435074224, 435063502, 335141021, 328507319, 418226238,
  398271233, 337016411, 445445226, 351121689, 342633196, 398319545, 328539074,
  328528518, 370194766, 351127310, 342618350, 350992075, 358119742, 337015576,
  328530483, 435068763, 445432410, 404290882, 445440739, 342632405, 386682222,
  358122475, 337012926, 334801479, 386942367, 378187510, 370200032, 445444339,
  342638767, 418219030, 328503506, 328507916, 370211487, 328495176, 350989295,
  378158613, 351046296, 328521744, 328499699, 378185763, 378145365, 342638033,
  335155248, 386955578, 378189742, 370178088, 335166547, 386695758, 398327453,
  357877467, 351045938, 342593153, 351140993, 328565014, 384115563, 342594798,
  342594016, 342614640, 342591190, 351152918, 386754623, 357883312, 328559956,
  329839470, 335153323, 370208074, 351058084, 328544786, 445446541, 445431829,
  370193543, 357810515, 370194099, 328524762, 351148747, 351046627, 378186905,
  335131430, 398316892, 357814858, 335091495, 370142504, 351045432, 370216600,
  351057416, 387779639, 378144244, 418225505, 342578683, 370204471, 418143075,
  328522422, 357813498, 335136993, 351049039, 418228431, 335150149, 357812643,
  378188233, 328506449, 370141772, 342582343, 328546381, 387780523, 330597170,
  435070411, 378175401, 328541524, 335138808, 334783124, 357812204, 357857342,
  335128012, 342583034, 445439401, 370216009, 386756478, 335180015, 370203657,
  435073627, 337015250, 398311670, 357810208, 334795728, 398307950, 396523835,
  370180272, 342575058, 398315817, 370202895, 378181799, 370221172, 351056633,
  351049598, 328500390, 335139687, 351018216, 378144874, 328505062, 398263290,
  398331833, 342581684, 358121671, 418217851, 398322208, 418139488, 435074828,
  334794211, 357880623, 445443099, 378156497, 328537993, 328520459, 328558722,
  328563689, 328539965, 335136344, 370175829, 351036049, 335137631, 342598236,
  350961963, 358122092, 418142231, 351159041, 370177059, 335141503, 386680355,
  328542664, 342574643, 378186307, 386681637, 386697020, 435072993, 357806075,
  342639884, 351142474, 335150903, 378178789, 398309860, 328501539, 386698016,
  378162620, 342589913, 398266816, 334800560, 342634860, 348421186, 334797107,
  370146144, 445439081, 378161184, 445444972, 370208620, 342629086, 378154517,
  328504167, 358120030, 335130906, 342610168, 370174670, 370192715, 334794938,
  386704623, 418222343, 378188959, 351047051, 378147187, 435064696, 445439766,
  378147789, 328560895, 435015927, 435071772, 342612630, 328494610, 370140833,
  351137771, 378177995, 335154671, 351149755, 351165061, 418140351, 418148879,
  386956116, 342597464, 435065284, 342602142, 342616181, 398310628, 386676630,
  350989983, 378148381, 378179429, 378159732, 418143875, 357806679, 370139149,
  342635929, 378157733, 378159296, 435076139, 350992944, 398330377, 326189642,
  357881713, 342584684, 342575821, 445441164, 445446049, 378176471, 328562025,
  350988162, 418137888, 418224422, 328550271, 351048078, 337015864, 370222171,
  342601496, 342631450, 386706145, 351058627, 418147746, 351056027, 342599162,
  418223699, 335127139, 435067688, 330599549, 370138614, 334802763, 328545759,
  378184850, 335119178, 398329775, 328536863, 378176036, 370212202, 330600155,
  351035582, 386678534, 386708332, 342600767, 335166961, 334782287
];

export { goannaAssets }