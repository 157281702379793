import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <div className="footer text-center d-flex justify-content-between py-5 bg-dark-table/50">
        <div className='bg-transparent'>
          <a className='bg-transparent hover:text-dark-brand2' href="https://discord.gg/al-goanna-879322634721067059" target="_blank" rel="noreferrer">
            <i className="fa-brands fa-discord fa-fw fa-2x"></i>
          </a>
          &nbsp;
          <a className='bg-transparent hover:text-dark-brand2' href="https://x.com/GoannaCouncil" target="_blank" rel="noreferrer">
            <i className="fa-brands fa-twitter fa-fw fa-2x"></i>
          </a>
        </div>
        <div className='bg-transparent'>
          <Link className='bg-transparent hover:text-dark-brand2' to={`/`}>Bank</Link>
          &nbsp;&middot;&nbsp;
          <Link className='bg-transparent hover:text-dark-brand2' to={`/terms`}>Terms</Link>
          &nbsp;&middot;&nbsp;
          <Link className='bg-transparent hover:text-dark-brand2' to={`/privacy`}>Policy</Link>
          &nbsp;&middot;&nbsp;
          <Link className='bg-transparent hover:text-dark-brand2' to={`/faq`}>FAQ</Link>
        </div>
      </div>
    </>
  );
};
export default Footer;
