import algosdk from 'algosdk';
import { getFreeLoans, lendNft } from '..';
import { base64Encode } from '../../../helpers/base64';
import { TransactionStates } from './utils';
// Custom error types for better error handling
class LendTransactionError extends Error {
  constructor(message, code) {
    super(message);
    this.name = 'LendTransactionError';
    this.code = code;
  }
}

export async function createLendModalTxn(
  nftId,
  loanLength,
  signProps
) {
  const { algodClient, signTransactions, activeAddress, onStateChange = () => { }, setSignTxn, setDisabled, setLoading, } = signProps;
  onStateChange(TransactionStates.PREPARING);
  setLoading(true)
  try {
    // Get available loans
    const data = await getFreeLoans();
    const appId = parseInt(data?.data);

    if (!appId || isNaN(appId) || typeof appId !== 'number') {
      throw new LendTransactionError('No available loans', 'NO_LOANS');
    }

    // Get transaction parameters
    const params = await algodClient.getTransactionParams().do();

    const appArgs = [
      new Uint8Array(Buffer.from('lend')),
    ];

    // Create lend transaction
    const lendTransaction = algosdk.makeApplicationNoOpTxnFromObject({
      suggestedParams: {
        ...params,
      },
      from: activeAddress,
      appIndex: appId,
      appArgs: appArgs,
      foreignAssets: [nftId],
    });

    lendTransaction.fee = 2000;

    // Create asset transfer transaction
    const assetTransferTransaction = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      suggestedParams: {
        ...params,
      },
      from: activeAddress,
      to: algosdk.getApplicationAddress(appId),
      assetIndex: nftId,
      amount: 1,
    });

    // Group transactions
    const txnsToGroup = [lendTransaction, assetTransferTransaction];
    const groupID = algosdk.computeGroupID(txnsToGroup);
    txnsToGroup.forEach(txn => txn.group = groupID);

    // Encode transactions
    const encodedGrp = txnsToGroup.map(t => algosdk.encodeUnsignedTransaction(t));

    onStateChange(TransactionStates.AWAITING_SIGNATURE);
    setSignTxn(true);
    setLoading(false)
    setDisabled(true);
    // Sign transactions
    const transactionsToSend = await signTransactions(encodedGrp);
    if (!transactionsToSend) {
      throw new LendTransactionError('Transaction signing failed', 'SIGNING_FAILED');
    }

    setSignTxn(false);

    // Encode and send transactions
    const decodedTxn = transactionsToSend.map(t => base64Encode(t));

    // Send transactions
    onStateChange(TransactionStates.SENDING);
    setLoading(true);
    // Send to blockchain
    const result = await lendNft(loanLength, nftId, decodedTxn, appId);

    onStateChange(TransactionStates.CONFIRMED);
    setDisabled(false);
    setLoading(false);
    setSignTxn(false);
    return {
      success: true,
      appId,
      message: 'Loaned NFT Successfully!'
    };

  } catch (error) {
    setDisabled(false);
    setLoading(false);
    setSignTxn(false);

    if (error instanceof LendTransactionError) {
      throw error;
    }

    // Convert other errors to our custom error type
    if (error.message?.includes('balance')) {
      throw new LendTransactionError('Insufficient balance for loan', 'INSUFFICIENT_BALANCE');
    }

    throw new LendTransactionError(
      error.message || 'Transaction failed',
      'TRANSACTION_FAILED'
    );
  }
}

// Usage example
export async function handleLendNFT(nftId, loanLength) {
  try {
    const result = await createLendModalTxn(
      nftId,
      loanLength,
      algodClient,
      activeAddress,
      signTransactions,
      setSignTxn
    );

    toast.success(result.message);
    return result;

  } catch (error) {
    switch (error.code) {
      case 'NO_LOANS':
        toast.error('No available loans.');
        break;
      case 'INSUFFICIENT_BALANCE':
        toast.error('NFT Loan Failed. Check your balance.');
        break;
      case 'SIGNING_FAILED':
        toast.error('Transaction was cancelled');
        break;
      default:
        toast.error('Transaction failed. Please try again.');
        console.error('Lend transaction error:', error);
    }
    return { success: false, error };
  }
}