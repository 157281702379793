import { generateClient } from 'aws-amplify/api'
import { listAPPS } from 'Queries/queries';
import { getNFTForApp } from '../../amplify/graphql/customQueries';
import { LoanByNftID } from '../../amplify/graphql/queries';

export const client = generateClient()

export const fetchLoanAnalytics = async (filter) => {
  let nextToken = null
  let allLoans = []

  do {
    const response = await client.graphql({
      query: listAPPS,
      variables: {
        filter,
        limit: 1000,
        nextToken
      }
    })

    const { items, nextToken: newNextToken } = response.data.listAPPS
    allLoans = [...allLoans, ...items]
    nextToken = newNextToken
  } while (nextToken)

  return allLoans
}

export async function getNftsForApps(filteredLoans, key) {
  if (!filteredLoans || filteredLoans?.length <= 0) return
  let loanHistoryNfts = []
  let intersection = filteredLoans.map(hist => hist?.global_lend_asset).filter(Boolean)
  intersection = [...new Set(intersection)];
  const responses = await Promise.all(
    intersection.map(async (x, y) => {
      return await client.graphql({
        query: getNFTForApp,
        variables: {
          id: x,
        }
      })
    })
  )
  responses.map(response => {
    if (response?.data?.getNFT) {
      loanHistoryNfts.push(response.data.getNFT)
    }

  })
  const loanHistoryMap = filteredLoans.map(lH => {
    const nft = loanHistoryNfts.find(l => l.id == lH.global_lend_asset)
    if (!nft) return undefined
    return {
      name: nft.name,
      app_id: lH.id,
      collection: nft.collection,
      image: nft.image,
      rank: nft.rank,
      image: nft.image,
      owner: undefined, // TODO FIND OWNER,
      isOwner: false, //allAssets?.includes(parseInt(nft.id)) || false,
      ...lH,
      id: nft.id,
    }
  })

  const groupedByCollection = loanHistoryMap.reduce((acc, nft) => {
    if (!acc[nft.collection]) {
      acc[nft.collection] = []
    }
    acc[nft.collection].push(nft)
    return acc
  }, {})
  const obj = { groupedByCollection }
  obj[key] = loanHistoryMap

  return obj
}

export async function getLoanByNftID(nftID) {
  try {
    const response = await client.graphql({
      query: LoanByNftID,
      variables: {
        nftID: nftID.toString(),
        filter: {
          datePaid: {
            ne: null // Only get records where datePaid is not null
          }
        }
      }
    });

    if (!response?.data?.LoanByNftID?.items) {
      return undefined;
    }
    // Sort items by datePaid in descending order
    const sortedItems = response.data.LoanByNftID.items.sort((a, b) => {
      const dateA = new Date(a.datePaid);
      const dateB = new Date(b.datePaid);
      return dateB - dateA; // Descending order
    });

    const dp = sortedItems[0]?.datePaid || undefined
    // Return the most recent datePaid
    return dp ? new Date(dp).getTime() / 1000 : undefined
  } catch (error) {
    console.error('Error fetching loan:', error);
    throw error;
  }
}
