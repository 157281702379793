import { DateFilterUtil } from "../../helpers/utils/filters";
import { decodeAppParamsG } from "../../helpers/utils/typecast";
import { getCreatedApps } from "../../helpers/utils/getters";

export async function* getApplicationsGenerator(creatorAddress, isMainnet = true) {
  const baseUrl = isMainnet
    ? 'https://mainnet-idx.algonode.cloud/v2'
    : 'https://testnet-idx.algonode.cloud/v2'

  try {
    let nextToken;
    do {
      let url = `${baseUrl}/applications?creator=${creatorAddress}`
      if (nextToken) {
        url += `&next=${nextToken}`
      }

      const response = await fetch(url)
      const data = await response.json()
      nextToken = data['next-token']

      // Yield each batch of applications
      yield data.applications;
    } while (nextToken)
  } catch (error) {
    console.error('Algonode API Error:', error)
    throw error
  }
}

export async function getMonthlyLoans(
  creatorAddress,
  algodClient
) {
  try {

    // let applications = await getCreatedApps(algodClient, creatorAddress)
    let applications = await getApplicationsGenerator(creatorAddress)
    console.log({ applications })
    applications = applications.map(app => decodeAppParamsG(app)).filter(Boolean)
    console.log({ decoded: applications })
    return { allLoans: applications, ...filterByMonth(applications) }
  } catch (error) {
    console.log("getMonthlyLoans: ", error.message)
    return []
  }
}

export function filterByMonth(applications) {
  try {
    let obj = {}
    const filtered = DateFilterUtil.filterByMonthRangeQ(applications, {
      months: 3,
      dateField: 'global_lend_date'
    });
    Object.keys(filtered).map(key => {
      const loans = [];
      let totalLoans = 0;
      let totalPayable = 0
      let totalPaid = 0
      let totalAssets = []
      const values = filtered[key]
      values.map(v => {
        const amount = v['global_lend_amount']
        const payback = v['global_lend_payback']
        const paid = v['global_lend_paid']
        const status = v['global_lend_status']
        const asset = v['global_lend_asset']
        const lendDate = v['global_lend_date']
        totalLoans += amount
        totalPayable += payback
        totalPaid += paid
        if (status !== 'debt_claimed') {
          totalAssets.push(asset)
        }
        loans.push({
          appId: v.id,
          global_lend_payback: payback,
          global_lend_status: status,
          global_lend_address: v['global_lend_address'],
          global_lend_time: v['global_lend_time'],
          global_lend_amount: amount,
          global_lend_asset: asset,
          global_lend_date: new Date(lendDate * 1000),
          global_lend_paid: v['global_lend_paid'],
        })
      })
      obj[key] = {
        totalLoans,
        loanCount: loans.length,
        loans: loans.sort((a, b) => b.global_lend_date.getTime() - a.global_lend_date.getTime()) // Sort by date descending
      }
      return
    })
    return obj
  } catch (error) {
    console.log("GET MONTHLY LOANS: ", error.message)
    return {
      totalLoans: 0,
      loanCount: 0,
      loans: [],
      error: error instanceof Error ? error.message : 'An unknown error occurred'
    };
  }
}