import { CircleStop } from "lucide-react";

export default function DropdownMenu({ value, onchange, options, func }) {
  return (
    <select value={value} onChange={onchange}
      className="w-52 origin-top-right rounded-tab border border-white/5 bg-white/5 p-1 text-xs/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 cursor-pointer"
    >
      {options && options.map(option => (
        <DropDownSelection key={option} value={option} text={func(option)} />
      ))}
    </select>
  );
};

const DropDownSelection = ({ value, text }) => {
  return <option

    value={value}
    className="text-dark-text group flex w-full items-center gap-2 py-1.5 px-3 cursor-pointer"
  >{text}</option>;
}

