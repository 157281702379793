import { useState, useEffect, useMemo } from 'react';

import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { Tab, TabGroup, TabList, } from '@headlessui/react';

import { ChevronLeft, ChevronRight, ArrowUpDown, ArrowUp, ArrowDown, } from 'lucide-react';

import { AlgoAmount } from "@algorandfoundation/algokit-utils/types/amount";

import { convertSecondsToDate, convertStateToView } from 'Apis/transactions';

import { Stats } from '../components/Cards/statCard';

import React from 'react';

import DropdownMenu from '../components/Tables/dropdown';

import { useLoanAnalyticsContext } from '../context/loanAnalyticsProvider';


export default function LoanAnalytics() {
  const [activeMonth, setActiveMonth] = useState('');
  const [activeMonthIndex, setActiveMonthIndex] = useState(0);
  const { isFetching, isPending: loading, isError, data, error } = useLoanAnalyticsContext();
  const [monthlyLoans, setMonthlyLoans] = useState()
  const [totalLoans, setTotalLoans] = useState()
  const [statusFilter, setStatusFilter] = useState('all');
  const [filteredLoans, setFilteredLoans] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [filteredData, setFilteredData] = useState([])
  const [pageSize, setPageSize] = useState(10)
  const [pageIndex, setPageIndex] = useState(0)
  // Get sorted months for selection
  const availableMonths = useMemo(() => {
    if (!filteredData) return
    return Object.keys(filteredData).sort((a, b) => b.localeCompare(a));
  }, [filteredData]);

  useEffect(() => {
    if (!data) return
    const fd = {}
    Object.keys(data).map(d => {
      if (d !== 'allLoans') {
        fd[d] = data[d]
        return undefined
      }
      return undefined
    })
    setFilteredData(fd)
  }, [data])
  // Set initial active month
  useEffect(() => {
    if (availableMonths && availableMonths.length > 0 && !activeMonth) {
      setActiveMonth(availableMonths[0]);
      setActiveMonthIndex(0)
    }
  }, [availableMonths]);

  // Calculate month-over-month changes
  const getMonthOverMonthChange = () => {
    try {
      if (!activeMonth) return { loanCount: 0, totalLoans: 0 };

      const monthIndex = availableMonths.indexOf(activeMonth);
      if (monthIndex === availableMonths.length - 1) return { loanCount: 0, totalLoans: 0 };

      const currentMonth = filteredData[activeMonth];
      const previousMonth = filteredData[availableMonths[monthIndex + 1]];

      return {
        loanCount: ((currentMonth.loanCount - previousMonth.loanCount) / previousMonth.loanCount * 100).toFixed(1),
        totalLoans: ((currentMonth.totalLoans - previousMonth.totalLoans) / previousMonth.totalLoans * 100).toFixed(1)
      };
    } catch (error) {
      return {
        loanCount: 0,
        totalLoans: 0
      }
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'global_lend_date',
        header: ({ column }) => (
          <button
            className="flex items-center space-x-1"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            <span>Date</span>
            {column.getIsSorted() ? (
              column.getIsSorted() === 'asc' ? (
                <ArrowUp className="w-4 h-4" />
              ) : (
                <ArrowDown className="w-4 h-4" />
              )
            ) : (
              <ArrowUpDown className="w-4 h-4 opacity-50" />
            )}
          </button>
        ),
        cell: (info) => new Date(info.getValue()).toLocaleDateString(),
        sortingFn: 'datetime'  // Needed because dates require special handling
      },
      {
        accessorKey: 'global_lend_amount',
        header: ({ column }) => (
          <button
            className="flex items-center space-x-1"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            <span>Amount (ALGO)</span>
            {column.getIsSorted() ? (
              column.getIsSorted() === 'asc' ? (
                <ArrowUp className="w-4 h-4" />
              ) : (
                <ArrowDown className="w-4 h-4" />
              )
            ) : (
              <ArrowUpDown className="w-4 h-4 opacity-50" />
            )}
          </button>
        ),
        cell: (info) => AlgoAmount.MicroAlgos(info.getValue()).algos.toLocaleString('en-US', {})
      },
      {
        accessorKey: 'global_lend_time',
        header: ({ column }) => (
          <button
            className="flex items-center space-x-1"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            <span>Loan Duration</span>
            {column.getIsSorted() ? (
              column.getIsSorted() === 'asc' ? (
                <ArrowUp className="w-4 h-4" />
              ) : (
                <ArrowDown className="w-4 h-4" />
              )
            ) : (
              <ArrowUpDown className="w-4 h-4 opacity-50" />
            )}
          </button>
        ),
        cell: (info) => (
          <span className="font-medium ">
            {convertSecondsToDate(info.getValue())}
          </span>
        )
        // Remove sortingFn - it will use default number sorting
      },
      {
        accessorKey: 'global_lend_status',
        header: 'Status',
        cell: (info) => {
          const status = info.getValue();
          return (
            <span
              className={`px-3 py-1 inline-flex text-xs leading-5 font-semibold rounded-full
                ${['paid', 'debt_claimed'].includes(status)
                  ? ''
                  : ''
                }`}
            >
              {convertStateToView(status)}
            </span>
          );
        }
      }
    ],
    []
  );

  // Filter loans based on active month and status
  useEffect(() => {
    if (!activeMonth) return

    setMonthlyLoans(activeMonth && filteredData[activeMonth] && AlgoAmount.MicroAlgos(filteredData[activeMonth]?.totalLoans || 0).algos.toLocaleString('en-US', {}))
    setTotalLoans(activeMonth && filteredData[activeMonth] && filteredData[activeMonth].loanCount)
    if (!filteredData[activeMonth] || filteredData[activeMonth].totalLoans <= 0) {
      setActiveMonth(availableMonths[activeMonthIndex + 1])
      return;
    }

    const monthlyLoans = filteredData[activeMonth]?.loans?.filter(
      (loan) =>
        statusFilter === 'all' ||
        (statusFilter === 'complete'
          ? ['paid', 'debt_claimed'].includes(loan.global_lend_status)
          : !['paid', 'debt_claimed'].includes(loan.global_lend_status))
    ) || []
    setFilteredLoans(monthlyLoans);
  }, [activeMonth, filteredData, statusFilter]);


  const handlePageSizeChange = (newSize) => {
    setPageSize(Number(newSize));
    setPageIndex(0); // Reset to first page when changing page size
  };

  const table = useReactTable({
    data: filteredLoans,
    columns,
    state: {
      sorting,
      pagination: {
        pageSize,
        pageIndex
      },
    },
    onSortingChange: setSorting,
    pageCount: Math.ceil(filteredLoans.length / pageSize),
    onPaginationChange: setPagination => {
      const newPagination = setPagination(
        { pageIndex, pageSize }
      );
      setPageIndex(newPagination.pageIndex);
      setPageSize(newPagination.pageSize);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: false,
  });


  const monthOverMonthChange = getMonthOverMonthChange();

  // if (error) {
  //   return <div className="p-4 text-dark-primary-400">Error loading data</div>;
  // }
  return (
    <div className="p-4 rounded-xl bg-contain max-w-screen-lg m-auto">
      {/* Stats Cards */}
      <div className='flex flex-wrap bg-cover relative rounded-2xl p-3'>
        <Stats
          monthlyLoans={monthlyLoans ? `${monthlyLoans} ALGO` : undefined}
          totalLoans={totalLoans}
          loading={loading || isFetching && !totalLoans}
        />
      </div>

      {/* Tabs */}
      <TabGroup onChange={(index) => setStatusFilter(['all', 'complete', 'pending'][index])}>
        <div className='flex flex-col-reverse sm:flex-row items-center justify-between'>
          <TabList className="flex gap-4 items-center border-b border-b-dark-border mb-4">
            {['All Loans', 'Completed', 'Pending'].map((category) => (
              <Tab
                key={category}
                className={(props) => {
                  const { selected, active, autofocus, disabled, focus, hover } = props
                  return `rounded-t-tab text-sm text-dark-text ${selected ? 'px-3 py-1.5 text-white bg-dark-table' : ''}`
                }}
              >
                {category}
              </Tab>
            ))}
          </TabList>
          {/* Month Selection */}
          <div className="flex justify-end mb-4">
            <DropdownMenu
              value={activeMonth}
              onchange={(e) => {
                const avalilableMonthIndex = availableMonths.findIndex(i => i == e.target.value)
                setActiveMonth(e.target.value)
              }}
              options={availableMonths}
              func={(month) => new Date(month + '-01').toLocaleString('default', { month: 'long', year: 'numeric' })}
            />
          </div>
        </div>
      </TabGroup>

      <div className='px-4 py-3 rounded-lg bg-dark-table mb-4 border border-dark-border dark:border-mutant-filter-border'>
        <div className="sm:flex-1 sm:flex sm:items-center sm:justify-between w-full">
          <div className='hidden sm:flex-1 sm:flex items-center gap-4'>
            <select
              value={pageSize}
              onChange={e => handlePageSizeChange(e.target.value)}
              className="rounded-md border border-white/5 bg-white/5 px-2 py-1 text-sm text-white focus:outline-none"
            >
              {[10, 25, 50].map(size => (
                <option key={size} value={size} className="bg-dark-table">
                  Show {size}
                </option>
              ))}
            </select>
            <p className="text-sm text-gray-700">
              Showing{' '}
              <span className="font-medium">
                {table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1}
              </span>{' '}
              to{' '}
              <span className="font-medium">
                {Math.min(
                  (table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize,
                  table.getPrePaginationRowModel().rows.length
                )}
              </span>{' '}
              of{' '}
              <span className="font-medium">{table.getPrePaginationRowModel().rows.length}</span>{' '}
              results
            </p>
          </div>

          <div className="flex flex-row gap-2 justify-between w-full sm:w-28">
            <button
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              className="rounded-tab border border-white/5 bg-white/5 p-1 text-xs/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 cursor-pointer disabled:bg-black/5"
            >
              <ChevronLeft className="w-5 h-5" />
            </button>
            <button
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
              className="rounded-tab border border-white/5 bg-white/5 p-1 text-xs/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 cursor-pointer disabled:bg-black/5"
            >
              <ChevronRight className="w-5 h-5" />
            </button>
          </div>
        </div>
      </div>

      {/* Table */}
      <div className="rounded-lg shadow-sm overflow-hidden bg-dark-table backdrop-blur-lg">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-opacity-50 rounded-xl">
            <thead className="">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      className="px-6 py-3 text-left text-xs font-medium text-dark-brand dark:text-mutant-brand uppercase tracking-wider"
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="divide-y divide-opacity-30">
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id} className="">
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className="text-white px-6 py-4 whitespace-nowrap text-sm "
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}



