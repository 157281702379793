const theme = {
  light: {
    // Text hierarchy
    text: {
      primary: 'text-light-gray-900',    // Most important text, headings
      secondary: 'text-light-gray-700',  // Regular content
      tertiary: 'text-light-gray-500',   // Less important text
      disabled: 'disabled:text-light-gray-400',   // Disabled text
      inverse: 'text-light-gray-50',     // Text on dark backgrounds
      success: 'text-light-green-700',   // Success messages
      accent: 'text-light-sage-700'      // Special emphasis
    },

    // Background colors
    bg: {
      primary: 'bg-light-gray-50',       // Main background
      secondary: 'bg-light-gray-100',    // Secondary background
      tertiary: 'bg-light-gray-200',     // Tertiary background
      accent: 'bg-light-sage-100',       // Accent background
      highlight: 'bg-light-green-50'     // Highlight background
    },

    // Buttons
    button: {
      primary: {
        base: 'bg-light-green-600 hover:bg-light-green-700 text-white',
        disabled: 'disabled:bg-light-gray-300 disabled:text-light-gray-500 disabled:cursor-not-allowed'
      },
      secondary: {
        base: 'bg-light-sage-600 hover:bg-light-sage-700 text-white',
        disabled: 'disabled:bg-light-gray-200 disabled:text-light-gray-400 disabled:cursor-not-allowed'
      },
      outline: {
        base: 'border-2 border-light-green-600 text-light-green-600 hover:bg-light-green-50',
        disabled: 'disabled:border-2 disabled:border-light-gray-300 disabled:text-light-gray-400 disabled:cursor-not-allowed'
      },
      ghost: {
        base: 'aria-selected:text-light-secondary-300 text-light-green-600 hover:bg-light-green-50',
        disabled: 'disabled:text-light-gray-400 disabled:cursor-not-allowed'
      }
    },

    // Borders
    border: {
      primary: 'border-light-gray-200',
      secondary: 'border-light-gray-300',
      focus: 'focus:border-light-green-500',
      error: 'border-red-500',
      success: 'border-light-green-500'
    },

    // Form inputs
    input: {
      base: 'bg-white border border-light-gray-300 focus:border-light-green-500 focus:ring-1 focus:ring-light-green-500',
      error: 'bg-white border border-red-500 focus:border-red-500 focus:ring-1 focus:ring-red-500',
      disabled: 'disabled:bg-light-gray-100 border disabled:border-light-gray-300 disabled:text-light-gray-500 disabled:cursor-not-allowed'
    },

    // Dividers
    divider: 'border-light-gray-200'
  },

  dark: {
    // Text hierarchy
    text: {
      primary: 'dark:text-dark-base-50',      // Most important text
      secondary: 'dark:text-dark-base-200',   // Regular content
      tertiary: 'dark:text-dark-base-300',    // Less important text
      disabled: 'dark:disabled:text-dark-base-400',    // Disabled text
      inverse: 'dark:text-dark-base-900',     // Text on light backgrounds
      success: 'dark:text-dark-primary-400',  // Success messages
      accent: 'dark:text-dark-secondary-400'  // Special emphasis
    },

    // Background colors
    bg: {
      primary: 'dark:bg-dark-base-900',       // Main background
      secondary: 'dark:bg-dark-base-800',     // Secondary background
      tertiary: 'dark:bg-dark-base-700',      // Tertiary background
      accent: 'dark:bg-dark-secondary-900',   // Accent background
      highlight: 'dark:bg-dark-primary-900'   // Highlight background
    },

    // Buttons
    button: {
      primary: {
        base: 'dark:bg-dark-secondary-700 dark:hover:bg-dark-secondary-300 dark:text-white',
        disabled: 'dark:disabled:bg-dark-base-700 dark:disabled:text-dark-base-500 dark:disabled:cursor-not-allowed'
      },
      secondary: {
        base: 'dark:bg-dark-secondary-600 dark:hover:bg-dark-secondary-700 dark:text-white',
        disabled: 'dark:disabled:bg-dark-base-700 dark:disabled:text-dark-base-500 dark:disabled:cursor-not-allowed'
      },
      outline: {
        base: 'dark:border-2 border-dark-primary-500 dark:text-dark-primary-500 dark:hover:bg-dark-base-800',
        disabled: 'dark:disabled:border-2 dark:disabled:border-dark-base-700 dark:disabled:text-dark-base-500 dark:disabled:cursor-not-allowed'
      },
      ghost: {
        base: 'dark:aria-selected:text-light-secondary-300 dark:text-light-gray-500 dark:hover:bg-dark-base-800',
        disabled: 'dark:disabled:text-dark-base-500 dark:disabled:cursor-not-allowed'
      }
    },

    // Borders
    border: {
      primary: 'dark:border-dark-base-700',
      secondary: 'dark:border-dark-base-600',
      focus: 'dark:focus:border-dark-primary-500',
      error: 'dark:border-red-500',
      success: 'dark:border-dark-primary-500'
    },

    // Form inputs
    input: {
      base: 'dark:bg-dark-base-800 border dark:border-dark-base-600 dark:focus:border-dark-primary-500 dark:focus:ring-1 dark:focus:ring-dark-primary-500',
      error: 'dark:bg-dark-base-800 border dark:border-red-500 dark:focus:border-red-500 dark:focus:ring-1 dark:focus:ring-red-500',
      disabled: 'dark:disabled:bg-dark-base-900 border dark:disabled:border-dark-base-700 dark:disabled:text-dark-base-500 disabled:cursor-not-allowed'
    },

    // Dividers
    divider: 'border-dark-base-700'
  }
};

// Usage example for a button component:
export const ButtonTheme = (variant = 'primary') => {
  const disabledLightClass = theme.light.button[variant].disabled
  const disabledDarkClass = theme.dark.button[variant].disabled
  const lightClass = theme.light.button[variant].base
  const darkClass = theme.dark.button[variant].base

  return `${disabledLightClass} ${lightClass} ${disabledDarkClass} ${darkClass}`;
};

// Usage example for an input:
export const InputTheme = ({ error, disabled, isDark = false }) => {
  const mode = isDark ? theme.dark : theme.light;
  let inputClasses = disabled
    ? mode.input.disabled
    : error
      ? mode.input.error
      : mode.input.base;

  return `${inputClasses}`;
};